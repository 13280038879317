var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('woot-modal',{attrs:{"show":_vm.show,"on-close":_vm.onClose},on:{"update:show":function($event){_vm.show=$event}}},[_c('woot-modal-header',{attrs:{"header-title":_vm.$t('PIPELINES.CREATE_STATUS')}}),_vm._v(" "),_c('div',{staticClass:"remainder-table"},[_c('div',{staticClass:"remainder-modal"},[_c('div',{staticClass:"remainder-input date-time"},[_c('div',{staticClass:"date-time-input"},[_c('label',[_vm._v("\n            "+_vm._s(_vm.$t('PIPELINES.EDIT_NAME'))+"\n            "),_c('span',{staticClass:"text-red-500"},[_vm._v("*")]),_vm._v(" "),_c('woot-input',{staticClass:"status-name mt-2",class:{ error: _vm.$v.name.$error },attrs:{"placeholder":_vm.$t('PIPELINES.ENTER_NAME'),"error":_vm.getLabelTitleErrorPipeline},on:{"input":_vm.$v.name.$touch},model:{value:(_vm.name),callback:function ($$v) {_vm.name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"name"}})],1),_vm._v(" "),_c('label',[_vm._v("\n            "+_vm._s(_vm.$t('PIPELINES.EDIT_DESCRIPTION'))+"\n            "),_vm._v(" "),_c('div',{staticClass:"my-editor mt-2"},[_c('div',{staticClass:"editor-ui rounded-md",class:{ error: _vm.$v.description.$error }},[_c('editor',{attrs:{"placeholder":'Add Remark...'},on:{"input":_vm.$v.description.$touch},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_vm._v(" "),(_vm.$v.description.$error)?_c('span',{staticClass:"text-red-500"},[_vm._v("\n                "+_vm._s(_vm.getDescriptionError)+"\n              ")]):_vm._e()])]),_vm._v(" "),_c('label',[_c('div',{staticClass:"color-picker-wrapper mt-2"},[_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('PIPELINES.SELECT_COLOR')))]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap gap-1"},_vm._l((_vm.colors),function(fixedColor,index){return _c('div',{key:index,style:({
                    backgroundColor: fixedColor,
                    width: '40px',
                    height: '40px',
                    cursor: 'pointer',
                    border: fixedColor === _vm.color ? '3px solid black' : '1px solid #ccc',
                    borderRadius: '50%',
                  }),attrs:{"title":fixedColor},on:{"click":function($event){return _vm.setColor(fixedColor)}}})}),0)])]),_vm._v(" "),_c('div',{staticClass:"flex justify-end items-center pb-6 pt-2 gap-2 w-full"},[_c('woot-submit-button',{staticClass:"button-color",attrs:{"disabled":!_vm.isSubmitEnabled || _vm.uiFlags.isCreating,"loading":_vm.uiFlags.isCreating,"button-text":'Submit'},on:{"click":_vm.createStatus}}),_vm._v(" "),_c('woot-button',{staticClass:"button clear cancel-btn",on:{"click":function($event){$event.preventDefault();return _vm.onClose($event)}}},[_vm._v("\n              "+_vm._s(_vm.$t('LABEL_MGMT.FORM.CANCEL'))+"\n            ")])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }