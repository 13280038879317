import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
export default {
    mixins: [alertMixin],
    data() {
        return {
            showDragmodal: false,
            timerRunning: false,
            seconds: 0,
            minutes: 0,
            hours: 0,
            selectContact: null
        };
    },
    computed: {
        ...mapGetters({
            currentChat: 'getSelectedChat',
            accountId: 'getCurrentAccountId',
            isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
            baseUrl: 'getbaseUrl',
            current_userId: 'getCurrentUser',
            currentUserId: 'getCurrentUserID',
        }),
        formattedTime() {
            return (
                this.pad(this.hours) +
                ':' +
                this.pad(this.minutes) +
                ':' +
                this.pad(this.seconds)
            );
        },
        // currentContact() {
        //     return this.$store.getters['contacts/getContact'](
        //         this.currentChat.meta.sender.id
        //     );
        // },
    },
    methods: {
        // async updateUIAcceptedOutgoingCall() {
        //     this.seconds = 0;
        //     this.minutes = 0;
        //     this.hours = 0;
        //     setTimeout(() => {
        //         this.startTimer();
        //     }, 1000);
        // },

        async updateUIDisconnectedOutgoingCall() {
            this.showDragmodal = !this.showDragmodal;
        },

        async makeOutgoingCall() {
            const contact = this.selectContact;
            let account_id = this.web_url.split('accounts/')[1].split('/')[0];
            let inbox_id = contact.contact_inboxes[0].inbox.id;
            var params = {
                to: contact.phone_number,
                account_id: account_id,
                contact_id: contact.id,
                inbox_id: inbox_id,
            };
            if (device) {
                console.log(`Attempting to call ${params.to} ...`);
                call = await device.connect({ params });
                this.seconds = 0;
                this.minutes = 0;
                this.hours = 0;
                call.on('accept', this.updateUIAcceptedOutgoingCall);
                call.on('disconnect', this.updateUIDisconnectedOutgoingCall);
                call.on('cancel', this.updateUIDisconnectedOutgoingCall);
            } else {
                console.log('Unable to make call.');
            }
        },

        // async CallHangup() {
        //     console.log('Call Disconnected');
        //     this.updateFormattedTime();
        //     call.disconnect();
        //     this.updateUIDisconnectedOutgoingCall();
        //     return;
        // },
        async CallHangup() {
            console.log('Call Disconnected');
            call.disconnect();
            this.$store.dispatch('contacts/toggleholdCallAvailableStatusFalse');
          },

        async getAudioDevices() {
            console.log('Inside getAudioDevices::::::::::::::::::::');
            await navigator.mediaDevices.getUserMedia({ audio: true });
        },

        addDeviceListeners(device) {
            device.on('registered', function () {
                console.log('Twilio.Device Ready to make and receive calls!');
            });
            device.on('error', function (error) {
                console.log('Twilio.Device Error: ' + error.message);
            });
        },

        async intitializeDevice() {
            console.log('Initializing device');
            device = new Twilio.Device(token, {
                logLevel: 1,
            });
            this.addDeviceListeners(device);
            device.register();
        },

        async startupClient(selectContact) {
            console.log('Requesting Access Token...');
            const contact = selectContact;
            this.selectContact=selectContact;
            console.log('contact::::', contact);
            let data; // Declare data variable outside the try block
            try {
            this.$store.dispatch('contacts/toggleholdCallAvailableStatusTrue');
            this.showDragmodal = !this.showDragmodal;
            this.getAudioDevices();
            console.log('Contact in yourMethod:', contact);
            console.log('User Id', this.currentUserId);
            // Remove redeclaration of data variable here
            const response = await axios.get(`${this.baseUrl}/twilio/token`, {
                params: {
                To: contact.phone_number,
                account_id: this.accountId,
                user_id: this.currentUserId,
                },
            });
            console.log("My Response::::::",response);
            data = response.data;

            token = data.token;
            console.log('got token' + token);
            await this.intitializeDevice();
            this.makeOutgoingCall();
            } catch (err) {
            this.showAlert(data.message);
            this.showDragmodal = !this.showDragmodal;
            console.error(
                'An error occurred. See your browser console for more information.'
            );
            }
        },
        startTimer() {
            this.timerRunning = true;
            this.timer = setInterval(this.updateTimer, 1000);
        },
        stopTimer() {
            clearInterval(this.timer);
            this.timerRunning = false;
        },
        updateTimer() {
            this.seconds++;
            if (this.seconds === 60) {
                this.seconds = 0;
                this.minutes++;
                if (this.minutes === 60) {
                    this.minutes = 0;
                    this.hours++;
                }
            }
        },
        pad(value) {
            return value < 10 ? '0' + value : value;
        },
        // updateFormattedTime() {
        //     // Your logic to update formattedTime
        //     this.$nextTick(() => {
        //         // Using $nextTick to ensure Vue has updated the DOM before retrieving the formattedTime
        //         console.log('Formatted time updated:', this.formattedTime);
        //     });
        // },
        resetTimer() {
            this.stopTimer();
            this.seconds = 0;
            this.minutes = 0;
            this.hours = 0;
          },
          async updateUIAcceptedOutgoingCall() {
            this.startTimer();
          },
      
          async updateUIDisconnectedOutgoingCall() {
            alert('Call Ended');
            this.showDragmodal = false;
            this.resetTimer();
            console.log('::::::::::::Call has ended::::::::::');
          },
    }
}
