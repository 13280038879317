<template>
  <div class="flex actions--container relative items-center gap-2">
    <woot-button
      v-tooltip="'call'"
      :icon="currentIcon"
      color-scheme="secondary"
      variant="clear"
      size="small"
      @click="startupClient"
    />
    <woot-button
      v-if="!currentChat.muted"
      v-tooltip="$t('CONTACT_PANEL.MUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-mute"
      @click="mute"
    />
    <woot-button
      v-else
      v-tooltip.left="$t('CONTACT_PANEL.UNMUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-1"
      @click="unmute"
    />
    <woot-button
      v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
      variant="clear"
      color-scheme="secondary"
      icon="share"
      @click="toggleEmailActionsModal"
    />
    <resolve-action
      :conversation-id="currentChat.id"
      :status="currentChat.status"
    />
    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
    <draggable-file
        :show="showDragmodal"
        :formatted="formattedTime"
        :hangup="CallHangup"
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import DraggableFile from '../WootWriter/Draggable.vue';
import EmailTranscriptModal from './EmailTranscriptModal';
import ResolveAction from '../../buttons/ResolveAction';
import {
  CMD_MUTE_CONVERSATION,
  CMD_SEND_TRANSCRIPT,
  CMD_UNMUTE_CONVERSATION,
} from '../../../routes/dashboard/commands/commandBarBusEvents';
let token;
let device;
let call;
export default {
  components: {
    EmailTranscriptModal,
    ResolveAction,
    DraggableFile
  },
  mixins: [alertMixin, clickaway],
  data() {
    return {
      showEmailActionsModal: false,
      currentIcon: 'call-start',
      showDragmodal: false,
      timerRunning: false,
      seconds: 0,
      minutes: 0,
      hours: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUserId: 'getCurrentUserID',
      baseUrl: 'getbaseUrl',
      accountId: 'getCurrentAccountId',
    }),
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta.sender.id
      );
    },
    formattedTime() {
      return (
        this.pad(this.hours) +
        ':' +
        this.pad(this.minutes) +
        ':' +
        this.pad(this.seconds)
      );
    },
  },
  mounted() {
    bus.$on(CMD_MUTE_CONVERSATION, this.mute);
    bus.$on(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$on(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  destroyed() {
    bus.$off(CMD_MUTE_CONVERSATION, this.mute);
    bus.$off(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$off(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  methods: {
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
    },
    async updateUIAcceptedOutgoingCall() {
      this.startTimer();
    },

    async updateUIDisconnectedOutgoingCall() {
      alert('Call Ended');
      this.showDragmodal = false;
      this.resetTimer();
      console.log('::::::::::::Call has ended::::::::::');
    },

    async makeOutgoingCall() {
      try {
        const contact = this.currentContact;
        console.log('Contact', contact);
        let inbox_id = contact.contact_inboxes[0].inbox.id;
        var params = {
          To: contact.phone_number,
          account_id: this.accountId,
          contact_id: contact.id,
          inbox_id: inbox_id,
          userId: this.currentUserId,
        };
        if (device) {
          console.log(`Attempting to call ${params.To} ...`);
          call = await device.connect({ params });
          call.on('accept', this.updateUIAcceptedOutgoingCall);
          call.on('disconnect', this.updateUIDisconnectedOutgoingCall);
          call.on('cancel', this.updateUIDisconnectedOutgoingCall);
        } else {
          console.log('Unable to make call.');
        }
      } catch (error) {
        console.error(
          'Error making outgoing call:-------------------------',
          error
        );
      }
    },

    async CallHangup() {
      console.log('Call Disconnected');
      call.disconnect();
      this.$store.dispatch('contacts/toggleholdCallAvailableStatusFalse');
    },

    async getAudioDevices() {
      console.log('Inside getAudioDevices::::::::::::::::::::');
      await navigator.mediaDevices.getUserMedia({ audio: true });
    },

    addDeviceListeners(device) {
      device.on('registered', function () {
        console.log('Twilio.Device Ready to make and receive calls!');
      });
      device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message);
      });
    },

    async intitializeDevice() {
      console.log('Initializing device');
      device = new Twilio.Device(token, {
        logLevel: 1,
      });
      this.addDeviceListeners(device);
      device.register();
    },
    async startupClient() {
      console.log('Requesting Access Token...');
      const contact = this.currentContact;
      console.log('contact::::', contact);
      let data; // Declare data variable outside the try block
      try {
        this.$store.dispatch('contacts/toggleholdCallAvailableStatusTrue');
        this.showDragmodal = !this.showDragmodal;
        this.getAudioDevices();
        console.log('Contact in yourMethod:', contact);
        console.log('User Id', this.currentUserId);
        
        const response = await axios.get(`${this.baseUrl}/twilio/token`, {
          params: {
            To: contact.phone_number,
            account_id: this.accountId,
            user_id: this.currentUserId,
          },
        });
        data = response.data;
        token = data.token;
        console.log('got token' + token);
        await this.intitializeDevice();
        this.makeOutgoingCall();
      } catch (err) {
        this.showAlert(data.message);
        this.showDragmodal = !this.showDragmodal;
        console.log(
          'An error occurred. See your browser console for more information.'
        );
      }
    },
    startTimer() {
      this.timerRunning = true;
      this.timer = setInterval(this.updateTimer, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      console.log('Stop timer', this.timer);
      this.timerRunning = false;
    },
    updateTimer() {
      console.log('UpdateTimer:::::::');
      this.seconds++;
      if (this.seconds === 60) {
        this.seconds = 0;
        this.minutes++;
        if (this.minutes === 60) {
          this.minutes = 0;
          this.hours++;
        }
      }
    },
    pad(value) {
      return value < 10 ? '0' + value : value;
    },
    resetTimer() {
      this.stopTimer();
      this.seconds = 0;
      this.minutes = 0;
      this.hours = 0;
    },
  },
};
</script>
<style scoped lang="scss">
.more--button {
  @apply items-center flex ml-2 rtl:ml-0 rtl:mr-2;
}

.dropdown-pane {
  @apply -right-2 top-12;
}

.icon {
  @apply mr-1 rtl:mr-0 rtl:ml-1 min-w-[1rem];
}
</style>
