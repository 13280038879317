<template>
  <div class="relative">
    <div
      class="flex px-4 pb-1 flex-row gap-1 pt-2.5 border-b border-transparent custom-margin"
    >
      <!-- <woot-sidemenu-icon
        size="tiny"
        class="relative top-0 ltr:-ml-1.5 rtl:-mr-1.5"
      /> -->
      <router-link
        :to="searchUrl"
        class="search-link flex-1 items-center gap-1 text-left h-6 rtl:mr-3 rtl:text-right rounded-md px-2 py-0 bg-slate-25 dark:bg-slate-800 inline-flex"
      >
        <div class="flex">
          <fluent-icon
            icon="search"
            class="search--icon text-slate-800 dark:text-slate-200"
            size="16"
          />
        </div>
        <p
          class="search--label mb-0 overflow-hidden whitespace-nowrap text-ellipsis text-sm text-slate-800 dark:text-slate-200"
        >
          {{ $t('CONVERSATION.SEARCH_MESSAGES') }}
        </p>
      </router-link>
      <!-- <switch-layout
        :is-on-expanded-layout="isOnExpandedLayout"
        @toggle="$emit('toggle-conversation-layout')"
      /> -->
      <!-- <div class="search-box">
         <fluent-icon
                icon="search"
              class="search-icon"
           /> -->

        <!-- <woot-input
          icon="search"
          v-model="inputValue"
          placeholder="search for messages in conversation"
          class="input-text"
          @input="emitInput"
        /> -

        <fluent-icon
          icon="search"
          :class="{'search-icon-filled': inputValue.trim() !== '', 'search-icon': inputValue.trim() === ''}"
          @click.native="handleEnter"
        />


        <woot-input
          icon="search"
          v-model="inputValue"
          placeholder="Search for messages in conversation"
          class="input-text"
          @keydown.enter.native="handleEnter" 
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import timeMixin from '../../../../mixins/time';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import SwitchLayout from './SwitchLayout.vue';
import { frontendURL } from 'dashboard/helper/URLHelper';
export default {
  components: {
    SwitchLayout,
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  data(){
    return {
      inputValue: ''
    };
  },
  mixins: [timeMixin, messageFormatterMixin, clickaway],
  props: {
    isOnExpandedLayout: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    searchUrl() {
      return frontendURL(`accounts/${this.accountId}/search`);
    },
  },
  watch: {
    // Watch inputValue changes
    inputValue(newValue) {
      if (newValue.trim() === '') {
        // Emit event to show all results when input is cleared
        bus.$emit('input-change', '');
        // console.log('Input is cleared, showing all results');
      }
    }
  },

  methods:{
    // emitInput(){
    //   bus.$emit('input-change',this.inputValue);
    // }
    handleEnter() {
      // Check if inputValue is not empty
      if (this.inputValue.trim() !== '') {
        // console.log('inputValue:', this.inputValue);
        bus.$emit('input-change', this.inputValue);
      } 
      // else {
      //   console.log('Input is empty, not emitting event');
      // }
    },
  }
};
</script>
<style lang="scss" scoped>
.search-box {
  position: relative;
  width: 100%;
}
.search-box >>> .input-text input {
  background-color: lightblue !important; /* Replace with your desired color */
}
.search-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  color: lightgray;
}
.search-icon-filled {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #3895c5; 
}
.search-icon, .search-icon-filled {
  cursor: pointer;  
}
.search-link {
  &:hover {
    .search--icon,
    .search--label {
      @apply hover:text-woot-500 dark:hover:text-woot-500;
      color: #40518a !important;
    }
  }
}
.custom-margin {
  margin-left: -4px;
}
</style>