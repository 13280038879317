<template>
  <div
    class="flex justify-between items-center h-14 min-h-[3.5rem] px-4 py-2 bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800/50"
  >
    <div class="flex items-center gap-4">
      <!-- <div class="back-icon" @click="handleBackClick">
        <fluent-icon
          v-tooltip="'Back'"
          icon="back"
          :viewBox="'0 0 52 52'"
          size="14"
          class="mt-0.5 back-contact cursor-pointer"
        />
      </div> -->
      <h1
        class="text-2xl mb-0 flex items-center text-slate-900 dark:text-slate-100"
      >
        <!-- <woot-sidemenu-icon v-if="showSidemenuIcon" />  -->
        <back-button
        v-if="showBackButton"
        :button-label="backButtonLabel"
        :back-url="backUrl"
      />
      <fluent-icon
        v-if="icon"
        :icon="icon"
        :class="iconClass"
        class="mr-2 ml-4 rtl:ml-2 rtl:mr-4"
      />
      <slot />
        <span class="text-slate-900 dark:text-slate-100 cursor-pointer" @click="openContact">{{
          headerTitle
        }}</span>
      </h1>
    </div>
    <router-link
      v-if="showNewButton && isAdmin"
      :to="buttonRoute"
      class="button success button--fixed-top"
    >
      <fluent-icon icon="add-circle" />
      <span class="button__content">
        {{ buttonText }}
      </span>
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BackButton from '../../../components/widgets/BackButton';
import adminMixin from '../../../mixins/isAdmin';

export default {
  components: {
    BackButton,
  },
  mixins: [adminMixin],
  props: {
    headerTitle: {
      default: '',
      type: String,
    },
    buttonRoute: {
      default: '',
      type: String,
    },
    buttonText: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    showBackButton: { type: Boolean, default: false },
    showNewButton: { type: Boolean, default: false },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    backButtonLabel: {
      type: String,
      default: '',
    },
    showSidemenuIcon: {
      type: Boolean,
      default: true,
    },
    openContact: {
       type: Function,
       default: () => {}
       },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    iconClass() {
      return `icon ${this.icon} header--icon`;
    },
  },
  methods: {
    handleBackClick() {
      console.log('account id :- ' + this.$route.params.accountId);
      // return `/app/accounts/${this.$route.params.accountId}/reports/overview`;
      this.$router.push({ name: 'settings_account_reports' });
    },
  }
};
</script>