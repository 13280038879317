<template>
    <woot-modal :show.sync="show" :on-close="onCancel">
      <div class="h-auto overflow-auto flex flex-col">
        <woot-modal-header
          :header-title="$t('NEW_CONVERSATION.TITLE')"
          :header-content="$t('NEW_CONVERSATION.DESC')"
        />
        <conversation-form
          :contact="updatedContact"
          :on-submit="onSubmit"
          @success="onSuccess"
          @cancel="onCancel"
        />
      </div>
    </woot-modal>
  </template>
  
  <script>
  import ConversationForm from './ConversationForm';
  
  export default {
    components: {
      ConversationForm,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      contact: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        updatedContact: {},
      };
    },
    watch: {
      contact: {
        immediate: true,
        handler(newContact) {
          this.updatedContact = { ...newContact };
          this.fetchInbox();
        },
      },
    },
    methods: {
      async fetchInbox() {
        const { id } = this.contact;
        if (id) {
          const inbox = await this.$store.dispatch('contacts/fetchContactableInbox', id);
          // Check if contactableInboxes exists and has at least one object, otherwise add default
          this.updatedContact = {
            ...this.updatedContact,
            contactableInboxes: inbox.contactableInboxes && inbox.contactableInboxes.length
              ? inbox.contactableInboxes
              : [],
          };
        }
      },
      onCancel() {
        this.$emit('cancel');
      },
      onSuccess() {
        this.$emit('cancel');
      },
      async onSubmit(contactItem) {
        const data = await this.$store.dispatch(
          'contactConversations/create',
          contactItem
        );
        return data;
      },
    },
  };
  </script>
  