<template>
  <svg
    :width="size"
    :height="size"
    fill="none"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!--<path
      v-for="source in pathSource"
      :key="source"
      :d="source"
      :transform="source.transform"
      fill="currentColor"
    /> -->
    <path
      v-for="(source, index) in pathSource"
      :key="index"
      :d="typeof source === 'string' ? source : source.d"
      :transform="
        typeof source === 'object' && source.transform ? source.transform : ''
      "
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '20',
    },
    type: {
      type: String,
      default: 'outline',
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
  },

  computed: {
    // pathSource() {
    //   // To support icons with multiple paths
    //   console.log("My Icon",this.icons);
    //   const path = this.icons[`${this.icon}-${this.type}`];
    //   console.log("My Path",path);
    //   if (path.constructor === Array) {
    //     return path;
    //   }
    //   return [path];
    // },

    pathSource() {
      // Get the path(s) based on the icon and type
      const pathData = this.icons[`${this.icon}-${this.type}`];
      // Case 1: If pathData is an array of objects (multiple paths with transforms)
      if (Array.isArray(pathData) && typeof pathData[0] === 'object') {
        return pathData;
      }

      // Case 2: If pathData is an array of strings (multiple paths without transforms)
      if (Array.isArray(pathData) && typeof pathData[0] === 'string') {
        return pathData.map(d => ({ d, transform: '' }));
      }

      // Case 3: If pathData is a single string (single path without transform)
      if (typeof pathData === 'string') {
        return [{ d: pathData, transform: '' }];
      }

      // Case 4: If pathData is a single object (single path with transform)
      if (typeof pathData === 'object' && pathData !== null) {
        return [pathData];
      }

      // Default case: Return an empty array if no valid path data is found
      return [];
    },
  },
};
</script>
