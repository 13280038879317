<template>
  <div>
    <div
      v-if="isOverlayVisible"
      class="overlay"
      @click="handleOverlayClick"
    ></div>
    <div
      v-if="hasSecondaryMenu"
      :class="[
        'h-full overflow-auto w-[13rem] flex flex-col bg-white dark:bg-slate-900 border-r dark:border-slate-800/50 rtl:border-r-0 rtl:border-l border-slate-50 text-sm px-2 pb-8',
        hoverClassActive ? 'hover-class' : '',
        displayNoneActive ? 'display-none' : '',
      ]"
      @mouseleave="handleMouseLeave"
    >
      <!-- <h5 class="heading-text">Conversations</h5> -->
      <h5 class="dark:text-slate-200 heading-text">{{ capitalizedParentNav }}</h5>
      <hr class="hr-style"/>
      <account-context @toggle-accounts="toggleAccountModal" />
      <transition-group
        name="menu-list"
        tag="ul"
        class="pt-2 list-none ml-0 mb-0"
      >
        <secondary-nav-item
          v-for="menuItem in accessibleMenuItems"
          :key="menuItem.toState"
          :menu-item="menuItem"
          @click.native="handleAdditionalNavItemClick"
        />
        <secondary-nav-item
          v-for="menuItem in additionalSecondaryMenuItems[menuConfig.parentNav]"
          :key="menuItem.key"
          :menu-item="menuItem"
          @add-label="showAddLabelPopup"
          @click.native="handleAdditionalNavItemClick"
        />
      </transition-group>
    </div>
  </div>
</template>
<script>
import { frontendURL } from '../../../helper/URLHelper';
import SecondaryNavItem from './SecondaryNavItem.vue';
import AccountContext from './AccountContext.vue';
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '../../../featureFlags';

export default {
  components: {
    AccountContext,
    SecondaryNavItem,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    inboxes: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    customViews: {
      type: Array,
      default: () => [],
    },
    menuConfig: {
      type: Object,
      default: () => {},
    },
    currentRole: {
      type: String,
      default: '',
    },
    isOnChatwootCloud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoverClassActive: false, // To control hover-class
      displayNoneActive: true,
      isOverlayVisible: false,
    };
  },
  mounted() {
    // Using EventBus
    bus.$on('toggle-secondary-menu', this.toggleSecondaryMenu);
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    hasSecondaryMenu() {
      return this.menuConfig.menuItems && this.menuConfig.menuItems.length;
    },
    contactCustomViews() {
      return this.customViews.filter(view => view.filter_type === 'contact');
    },
    accessibleMenuItems() {
      if (!this.currentRole) {
        return [];
      }
      const menuItemsFilteredByRole = this.menuConfig.menuItems.filter(
        menuItem =>
          window.roleWiseRoutes[this.currentRole].indexOf(
            menuItem.toStateName
          ) > -1
      );
      return menuItemsFilteredByRole.filter(item => {
        if (item.showOnlyOnCloud) {
          return this.isOnChatwootCloud;
        }
        return true;
      });
    },
    inboxSection() {
      return {
        icon: 'folder',
        label: 'INBOXES',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.INBOX_MANAGEMENT),
        newLinkTag: 'NEW_INBOX',
        key: 'inbox',
        toState: frontendURL(`accounts/${this.accountId}/settings/inboxes/new`),
        toStateName: 'settings_inbox_new',
        newLinkRouteName: 'settings_inbox_new',
        children: this.inboxes
          .map(inbox => ({
            id: inbox.id,
            label: inbox.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/inbox/${inbox.id}`
            ),
            type: inbox.channel_type,
            phoneNumber: inbox.phone_number,
            reauthorizationRequired: inbox.reauthorization_required,
          }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          ),
      };
    },
    labelSection() {
      return {
        icon: 'number-symbol',
        label: 'LABELS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        key: 'label',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/label/${label.title}`
          ),
        })),
      };
    },
    contactLabelSection() {
      return {
        icon: 'number-symbol',
        label: 'TAGGED_WITH',
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/labels/${label.title}/contacts`
          ),
        })),
      };
    },
    teamSection() {
      return {
        icon: 'people-team',
        label: 'TEAMS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_TEAM',
        key: 'team',
        toState: frontendURL(`accounts/${this.accountId}/settings/teams/new`),
        toStateName: 'settings_teams_new',
        newLinkRouteName: 'settings_teams_new',
        children: this.teams.map(team => ({
          id: team.id,
          label: team.name,
          truncateLabel: true,
          toState: frontendURL(`accounts/${this.accountId}/team/${team.id}`),
        })),
      };
    },
    foldersSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_FOLDER',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'conversation')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/custom_view/${view.id}`
            ),
          })),
      };
    },
    contactSegmentsSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_SEGMENTS',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'contact')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/contacts/custom_view/${view.id}`
            ),
          })),
      };
    },
    additionalSecondaryMenuItems() {
      let conversationMenuItems = [this.inboxSection, this.labelSection];
      let contactMenuItems = [this.contactLabelSection];
      if (this.teams.length) {
        conversationMenuItems = [this.teamSection, ...conversationMenuItems];
      }
      if (this.customViews.length) {
        conversationMenuItems = [this.foldersSection, ...conversationMenuItems];
      }
      if (this.contactCustomViews.length) {
        contactMenuItems = [this.contactSegmentsSection, ...contactMenuItems];
      }
      return {
        conversations: conversationMenuItems,
        contacts: contactMenuItems,
      };
    },
    capitalizedParentNav() {
    if (this.menuConfig.parentNav) {
      return this.menuConfig.parentNav.charAt(0).toUpperCase() + this.menuConfig.parentNav.slice(1);
    }
    return '';
  },
  },
  methods: {
    toggleSecondaryMenu(isVisible) {
      this.hoverClassActive = isVisible;
      this.displayNoneActive = !isVisible;
      this.isOverlayVisible = isVisible;
    },
    showAddLabelPopup() {
      this.$emit('add-label');
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    showNewLink(featureFlag) {
      return this.isFeatureEnabledonAccount(this.accountId, featureFlag);
    },
    handleAdditionalNavItemClick() {
      // Toggle class states on additional nav item click
      this.hoverClassActive = false;
      this.displayNoneActive = true;
      this.isOverlayVisible = true;
    },
    handleMouseLeave() {
      this.displayNoneActive = true; // Set display-none to true when mouse leaves
      this.isOverlayVisible = false;
    },
    handleOverlayClick() {
      // Hide secondary menu and overlay when the overlay is clicked
      this.hoverClassActive = false;
      this.displayNoneActive = true;
      this.isOverlayVisible = false;
    },
  },
};
</script>
<style scoped>
.overlay {
  position: fixed; /* Fixed positioning to cover the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 10; /* Make sure it's above the other content */
}

.hover-class {
  position: absolute;
  z-index: 30;
  left: 55px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}
body:not(.dark) .hover-class {
  background-color: aliceblue;
}
.display-none {
  display: none;
}
.heading-text {
  font-weight: 700;
  text-align: center;
  margin: 5px 0px;
}
body:not(.dark) .heading-text{
  color: #40518a;
}
.hr-style {
  margin: 5px !important;
  color: gray;
}
</style>