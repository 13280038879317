<template>
    <div v-if="show" class="filterClass w-[12.5rem]">
      <div class="modal-content w-[12.5rem] rounded-md cursor-pointer">
        <label>
          <input type="checkbox" />
          Name
        </label>
        <label>
          <input type="checkbox" />
          Email Address
        </label>
        <label>
          <input type="checkbox" />
          Phone Number
        </label>
        <label>
          <input type="checkbox" />
          Created At
        </label>
        <label>
          <input type="checkbox" />
          Source
        </label>
        <label>
          <input type="checkbox" />
          Company
        </label>
        <label>
          <input type="checkbox" />
          City
        </label>
        <label>
          <input type="checkbox" />
          Conversations
        </label>
        <label>
          <input type="checkbox" />
          Country
        </label>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import ContactForm from './ContactForm';
  
  export default {
    components: {
      ContactForm,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      contact: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        extraValue: false,
      };
    },
  
    computed: {
      ...mapGetters({
        uiFlags: 'contacts/getUIFlags',
      }),
    },
  
    methods: {
      onCancel() {
        this.$emit('cancel');
      },
      onSuccess() {
        this.$emit('cancel');
      },
      async onSubmit(contactItem) {
        try {
          const current_contact = await this.$store.dispatch(
            'contacts/create',
            contactItem
          );
          if (current_contact.success == false) {
            this.extraValue = true;
            const existing_contact_id = current_contact.contact[0].id;
            const account_id = current_contact.contact[0].account_id;
            this.$router.push(
              `/app/accounts/${account_id}/contacts/${existing_contact_id}`
            );
          } else {
            this.$router.push(
              `/app/accounts/${this.$route.params.accountId}/contacts/${current_contact.id}`
            );
          }
        } catch (error) {
          console.error('Error creating contact:', error);
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .filterClass {
    position: fixed;
    left: 84%;
    top: 57px;
    z-index: 9990;
    display: flex;
    align-items: flex-start;
    justify-content: end;
    /* background-color: rgba(0, 0, 0, 0.4); */
    right: 10px;
  }
  .modal-content{
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 3px 6px #00000029;
  }
  </style>