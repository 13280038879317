<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    
    <a
      :href="href"
      v-tooltip.right="$t(`SIDEBAR.${name}`)"
      class="text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
      :class="{
        'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50 background-light':
          isActive || isChildMenuActive,
      }"
      :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
      :target="openInNewPage ? '_blank' : undefined"
      @click="navigate"
     @mouseenter="handleMouseEnter($event, href, navigate)"
    >
      <fluent-icon
        :icon="icon"
        :viewBox="'-10 -10 50 50'"
        :size="'40'"
        class="light-grey"
        :class="{
          'text-woot-500 white-color': isActive || isChildMenuActive,
        }"
      />
      <span class="sr-only">{{ name }}</span>
      <span
        v-if="count"
        class="text-black-900 bg-yellow-500 absolute -top-1 -right-1"
      >
        {{ count }}
      </span>
      <span
        v-if="
          hasUnreadMessages &&
          isConversations &&
          !isActive &&
          !isChildMenuActive
        "
        class="unread-indicator"
      ></span>
    </a>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasUnreadMessages: false,
      previousConversationId: null,
      isHelpCenterTooltip: false, 
    };
  },
  computed: {
    isConversations() {
      return this.name === 'CONVERSATIONS';
    },
  },
  mounted() {
    bus.$on('emit-data', this.handleNewMessage);
    this.$watch('$route', this.checkIfActive);
  },
  methods: {
    showSecondaryMenu() {
      bus.$emit('toggle-secondary-menu', true);
    },
    handleNewMessage(data) {
      // console.log('New message received:', data);
      const messageId = data.id;
      // console.log('New message ID:', messageId);

      // Check if the conversation page is active
      if (!this.isConversationActive()) {
        if (messageId !== this.previousConversationId) {
          this.hasUnreadMessages = true;
        }
      } else {
        this.hasUnreadMessages = false;
      }
      this.previousConversationId = messageId;
    },
    checkIfActive() {
      if (this.isConversations && this.$route.path === this.to) {
        this.hasUnreadMessages = false;
      }
    },
    isConversationActive() {
    // console.log("Current Routee1111:", this.$route); 
    // console.log("Current Routee name:", this.$route.name); 
    // console.log("Current Routee path:", this.$route.path); 
  
    const expectedName = 'inbox_conversation';
    const expectedPath = '/app/accounts/1/dashboard';
    
  return this.$route.name === expectedName || this.$route.path === expectedPath;
  },
  handleMouseEnter(event, href, navigate) {
    if (this.name === 'HELP_CENTER.TITLE') {
    bus.$emit('toggle-secondary-menu', false);
    return;
  } 
    // Pass the event to navigate
    bus.$emit('on-route-change-hover', href);
    this.showSecondaryMenu();
  },
  },
};
</script>
<style scoped>
.background-light {
  background-color: #6e86d8;
}
.white-color {
  color: #fff !important;
}
.light-grey {
  color: lightgray;
}
.unread-indicator {
  position: absolute;
  top: 4px;
  right: 6px;
  width: 11px;
  height: 11px;
  background-color: #08bb08;
  border-radius: 50%;
}
</style>