<template>
  <div class="outer-container flex justify-center">
    <div
      v-if="filteredPreviousIcons.length > 0"
      class="flex flex-col items-center pt-6 pb-6 p-0 bg-white dark:bg-slate-800 sm:gap-3 md:gap-4 lg:gap-5 xl:gap-6"
    >
      <woot-button
        v-for="(icon, index) in filteredPreviousIcons"
        :key="index"
        class="custom-button messenger"
        @click="openConversation(icon.conversationId)"
      >
        <span
          v-if="
            icon.unreadCount > 0 &&
            !(
              icon.conversationId === currentConversationId ||
              currentId === icon.conversationId
            )
          "
          class="unread-count"
        >
          {{ icon.unreadCount }}
        </span>
        <fluent-icon
          :icon="icon.data"
          :viewBox="icon.viewBox"
          :size="'50'"
          :class="[
            icon.class,
            {
              'active-icon':
                icon.conversationId === currentConversationId ||
                currentId === icon.conversationId,
            },
          ]"
        />
        <!-- Display the unread_count if it is greater than 0 -->
      </woot-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    conversationId: {
      type: [String, Number],
      required: true,
    },
    // messages: {
    //   type: [Array],
    //   required: true,
    // },
  },
  data() {
    return {
      icons: [
        {
          name: 'Whatsapp',
          data: 'whatsapp',
          viewBox: '-4 -4 35.676 35.676',
          class: 'whatsapp',
        },
        {
          name: 'Facebook',
          data: 'facebook',
          viewBox: '-4 -4 35.676 35.676',
          class: 'insta-icon',
        },
        {
          name: 'Telegram',
          data: 'telegram',
          viewBox: '-4 -4 35.676 35.676',
          class: 'insta-icon',
        },

        {
          name: 'Email',
          data: 'mail',
          viewBox: '-5 -4 35.676 35.676',
          class: 'email',
        },
        {
          name: 'Messenger',
          data: 'messenger',
          viewBox: '-1 0 35.676 35.676',
          class: 'insta-icon',
        },
        {
          name: 'Instagram',
          data: 'instagram',
          viewBox: '-10 -10 53 53',
          class: 'insta-icon',
        },
        {
          name: 'Twitter',
          data: 'twitter',
          viewBox: '0 0 512 512',
          class: 'insta-icon',
        },
        {
          name: 'WebWidget',
          data: 'web',
          viewBox: '-7 -10 53 53',
          class: 'insta-icon',
        },
      ],
      currentConversationId: null,
      currentUrl: '',
      lastChat: {},
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contactConversations/getUIFlags',
      currentChat: 'getSelectedChat',
    }),
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    channelName() {
      return this.currentChat.meta?.channel?.split('::')[1];
    },
    filteredIcons() {
      return this.icons.filter(icon =>
        icon.name.toLowerCase().includes(this.channelName.toLowerCase())
      );
    },
    conversations() {
      return this.$store.getters['contactConversations/getContactConversation'](
        this.contactId
      );
    },
    currentId() {
      const url = this.currentUrl.split('/');
      return Number(url[url.length - 1]);
    },
    previousConversations() {
      return this.conversations.sort((a, b) => b.timestamp - a.timestamp);
    },
    filteredPreviousIcons() {
      return this.previousConversations
        .map(conversation => {
          const channel = conversation.meta?.channel?.split('::')[1];
          const icon = this.icons.find(
            icon => icon.name.toLowerCase() === channel.toLowerCase()
          );

          // Include unread_count with the icon if it exists
          return icon
            ? {
                ...icon,
                conversationId: conversation.id,
                unreadCount: conversation.unread_count, // Add unread_count here
              }
            : null;
        })
        .filter(icon => icon); // Filter out null values if no match is found
    },
  },
  watch: {
    conversationId(newVal, oldVal) {
      this.$store.dispatch('contactConversations/get', this.contactId);
    },
    $route(to, from) {
      this.currentUrl = to.fullPath;
    },
  },
  mounted() {
    this.$store.dispatch('contactConversations/get', this.contactId);
    this.currentUrl = this.$route.fullPath;
    bus.$on('emit-data', data => {
      this.$store.dispatch('contactConversations/get', this.contactId);
    });
  },
  methods: {
    openConversation(conversationId) {
      this.currentConversationId = conversationId;
      const conversation = this.currentUrl.split('/');
      const id = conversation[conversation.length - 1];
      if (Number(id) !== conversationId) {
        this.$router.push({
          name: 'inbox_conversation',
          params: { conversation_id: conversationId },
        });
      }
    },
  },
};
</script>


<style scoped>
.outer-container {
  width: 72px;
  height: 100%;
  overflow-y: auto;
  padding: 1px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: -10px;
  background-color: #fbfbfb;
  margin-top: 0px;
}
.custom-button {
  background-color: #fff !important;
}

.custom-button >>> svg {
  width: 24px;
  height: 24px;
  color: #5a524e;
  transition: color 0.3s ease;
}
.inner-container:hover .custom-button {
  background-color: white;
}
.instagram >>> span {
  width: auto;
}
.messenger >>> span {
  width: auto;
}
.web >>> span {
  width: auto;
}
.twitter >>> span {
  width: auto;
}
.insta-icon,
.email,
.whatsapp {
  width: 40px !important;
  height: 40px !important;
}
.insta-icon:hover {
  color: blue; /* Alternatively, change the text or icon color if applicable */
}
.whatsapp:hover {
  color: #44ce4b;
}
.facebook:hover {
  color: blue;
}
.email:hover {
  color: red;
}
.active-icon {
  color: #40518a !important;
  background-color: deepskyblue;
  border-radius: 5px; /* Change this to the desired active color */
}
.unread-count {
  position: absolute;
  right: 15px;
  background-color: #40518a;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
</style>  