import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/state'),
      roles: ['administrator'],
      component: SettingsContent,
      props: {
        headerTitle: 'GENERAL_SETTINGS.STATE.TITLE',
        icon: 'location',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'state',
          component: Index,
          roles: ['administrator'],
        },
      ],
    },
  ],
};