/* global axios */
import ApiClient from './ApiClient';

export const buildContactParams = (page, sortAttr, label, search,state_id,status_id) => {
  let params = `include_contact_inboxes=false&page=${page}&sort=${sortAttr}`;
  if (search) {
    params = `${params}&q=${search}`;
  }
  if (label) {
    params = `${params}&labels[]=${label}`;
  }
  if (state_id){
    params = `${params}&state_id=${state_id}`;
  }
  if (status_id){
    params = `${params}&status_id=${status_id}`;
  }
  return params;
};

class ContactAPI extends ApiClient {
  constructor() {
    super('contacts', { accountScoped: true });
  }

  get(page, sortAttr = 'name', label = '') {
    let requestURL = `${this.url}?${buildContactParams(
      page,
      sortAttr,
      label,
      ''
    )}`;
    return axios.get(requestURL);
  }

  getConversations(contactId) {
    return axios.get(`${this.url}/${contactId}/conversations`);
  }

  getContactableInboxes(contactId) {
    return axios.get(`${this.url}/${contactId}/contactable_inboxes`);
  }

  getContactLabels(contactId) {
    return axios.get(`${this.url}/${contactId}/labels`);
  }

  updateContactLabels(contactId, labels) {
    return axios.post(`${this.url}/${contactId}/labels`, { labels });
  }

  search(search = '', page = 1, sortAttr = 'name', label = '',state_id,status_id) {
    let requestURL = `${this.url}/search?${buildContactParams(
      page,
      sortAttr,
      label,
      search,
      state_id,
      status_id
    )}`;
    return axios.get(requestURL);
  }

  filter(page = 1, sortAttr = 'name', queryPayload) {
    let requestURL = `${this.url}/filter?${buildContactParams(page, sortAttr)}`;
    return axios.post(requestURL, queryPayload);
  }

  importContacts(file, inboxId, agentList) {
    const formData = new FormData();
    formData.append('import_file', file);
    formData.append('inbox_id', inboxId);
    formData.append('agent_list', agentList);
    return axios.post(`${this.url}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  destroyCustomAttributes(contactId, customAttributes) {
    return axios.post(`${this.url}/${contactId}/destroy_custom_attributes`, {
      custom_attributes: customAttributes,
    });
  }

  destroyAvatar(contactId) {
    return axios.delete(`${this.url}/${contactId}/avatar`);
  }

  exportContacts() {
    return axios.get(`${this.url}/export`);
  }

  getAttachments(contactId) {
    return axios.get(`${this.url}/${contactId}/contact_attachments`);
  }

  update_demo(conversationId, data) {
    const url = this.url;
    const parts = url.split('/');
    parts.pop(); // Remove the last part
    const newUrl = parts.join('/');
    return axios.patch(`${newUrl}/conversations/${conversationId}/demo_time`, {
      scheduleType: data.scheduleType,
      calendar_timings: data.calendar_timings,
      meet_link: data.meetingLink || null,
      message: data.messages
    });
  }
  getstate(id,contact_id){
    console.log("Hi:::::::",id,contact_id);
    const updatedUrl = this.url.replace('/contacts', '');
    return axios.get(`${updatedUrl}/states/${id}?contact_id=${contact_id}`);
  }
  assignstatus(contactId,statusId){
    const updatedUrl = this.url.replace('/pipelines', '' );
    return axios.put(`${updatedUrl}/contacts/${contactId}`,{
      status_id: statusId
    });
  }
  assignstate(contactId,stateId,description){
    // const updatedUrl = this.url.replace('/pipelines', '' );
    return axios.put(`${this.url}/${contactId}`,{
      state_id:stateId,
      state_description:description
    });
  }
  getboard(id){
    const updatedUrl = this.url.replace('/contacts', '');
    console.log("Hello",this.url,updatedUrl,id);
    return axios.get(`${updatedUrl}/board/${id}`);
  }
  assignboard(contactId,boardId,description){
    // const updatedUrl = this.url.replace('/pipelines', '' );
    return axios.put(`${this.url}/${contactId}`,{
      board_id:boardId,
      description:description
    });
  }
  sendreport(data){
    return axios.post(`${this.url}/send_report`,data);
  }
}

export default new ContactAPI();