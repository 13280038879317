<template>
    <woot-modal :show.sync="show" :on-close="onCancel" class="custom-modal">
      <div class="h-auto overflow-auto flex flex-col">
        <woot-modal-header :header-title="'Add State'" />
        <div class="flex justify-item">
          <div class="w-[90%]">
            <select>
              <option>Warm</option>
              <option>Kivo CRM-Calls API</option>
              <option>Kivo CRM-Calls API</option>
              <option>Kivo CRM-Calls API</option>
              <option>Kivo CRM-Calls API</option>
            </select>
          </div>
        </div>
        <div class="my-editor px-6">
          <div class="editor-ui rounded-md">
            <editor :placeholder="'Add Remark...'"/>
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-2 py-2 px-0 w-full">
        <div class="w-full flex justify-center">
          <woot-submit-button
            class="button-color"
            :button-text="$t('CONTACT_FORM.FORM.SUBMIT')"
            @click="createState"
          />
          <button class="button clear color" @click.prevent="onCancel">
            {{ $t('CONTACT_FORM.FORM.CANCEL') }}
          </button>
        </div>
      </div>
    </woot-modal>
  </template>
  
  <script>
  import Editor from 'dashboard/components/widgets/WootWriter/Editor.vue';
  export default {
    components: {
      Editor,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onCancel() {
        this.$emit('cancel');
      },
      async createState() {
        this.onCancel();
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .justify-item {
    justify-content: space-around;
  }
  .button-color {
    background-color: #40518a;
  }
  .color {
    color: #40518a;
  }
  .editor-ui{
    border: 2px solid lightgrey;
    height: 200px;
    padding-left: 10px;
  }
  </style>