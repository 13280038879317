<template>
  <woot-modal :show.sync="show" :on-close="onCancel" class="custom-modal">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t('EDIT_CONTACT.TITLE')"
        :header-content="$t('CREATE_CONTACT.DESC')"
      />
      <new-edit-form
        :contact="contact"
        :in-progress="uiFlags.isCreating"
        :on-submit="onSubmit"
        @cancel="onCancel"
        @success="onSuccess"
      />
    </div>
  </woot-modal>
</template>
  
  <script>
import { mapGetters } from 'vuex';
import NewEditForm from './NewEditForm';

export default {
  components: {
    NewEditForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('cancel');
    },
    async onSubmit(contactItem) {
      await this.$store.dispatch('contacts/update', contactItem);
      await this.$store.dispatch(
        'contacts/fetchContactableInbox',
        this.contact.id
      );
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>