var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer-container flex justify-center"},[(_vm.filteredPreviousIcons.length > 0)?_c('div',{staticClass:"flex flex-col items-center pt-6 pb-6 p-0 bg-white dark:bg-slate-800 sm:gap-3 md:gap-4 lg:gap-5 xl:gap-6"},_vm._l((_vm.filteredPreviousIcons),function(icon,index){return _c('woot-button',{key:index,staticClass:"custom-button messenger",on:{"click":function($event){return _vm.openConversation(icon.conversationId)}}},[(
          icon.unreadCount > 0 &&
          !(
            icon.conversationId === _vm.currentConversationId ||
            _vm.currentId === icon.conversationId
          )
        )?_c('span',{staticClass:"unread-count"},[_vm._v("\n        "+_vm._s(icon.unreadCount)+"\n      ")]):_vm._e(),_vm._v(" "),_c('fluent-icon',{class:[
          icon.class,
          {
            'active-icon':
              icon.conversationId === _vm.currentConversationId ||
              _vm.currentId === icon.conversationId,
          } ],attrs:{"icon":icon.data,"viewBox":icon.viewBox,"size":'50'}})],1)}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }