var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"dropdownContainer",staticClass:"dropdown-container flex",on:{"click":_vm.toggleDropdown}},[_c('div',{staticClass:"dropdown-selected"},[_vm._v("\n    "+_vm._s(_vm.selectedOption
        ? _vm.selectedOption.name.length > 15
          ? _vm.selectedOption.name.substring(0, 15) + '...'
          : _vm.selectedOption.name
        : _vm.placeholder)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"calendar"},[_c('svg',{attrs:{"fill":"#ccc","width":"15px","height":"15px","viewBox":"-6.5 0 32 32","version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('title',[_vm._v("dropdown")]),_vm._v(" "),_c('path',{attrs:{"d":"M18.813 11.406l-7.906 9.906c-0.75 0.906-1.906 0.906-2.625 0l-7.906-9.906c-0.75-0.938-0.375-1.656 0.781-1.656h16.875c1.188 0 1.531 0.719 0.781 1.656z"}})])]),_vm._v(" "),(_vm.isOpen)?_c('div',{staticClass:"dropdown-options bg-white dark:bg-slate-900"},_vm._l((_vm.status),function(state){return _c('div',{key:state.id,staticClass:"dropdown-option",class:{
        'selected-option': _vm.selectedOption && _vm.selectedOption.id === state.id,
      },attrs:{"title":state.name.length > 15 ? state.name : ''},on:{"click":function($event){return _vm.selectOption(state)}}},[_vm._v("\n    "+_vm._s(state.name.length > 15
          ? state.name.substring(0, 15) + '...'
          : state.name)+"\n    ")])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }