<template>
  <woot-modal :show.sync="show" :on-close="onCancel" class="custom-modal">
    <div class="h-auto overflow-auto flex flex-col">
    

        <!-- Header Section -->
        <div class="flex items-center justify-between px-4 py-2">
        <div class="flex items-center gap-2">
         
          <woot-modal-header :header-title="'Edit Status'" />
          
       
          <a
            href="#"
            @click.prevent="navigateToAddNewStatus"
            class="text-blue-500 text-sm font-medium hover:underline add-new-state-link"
          >
            (+ Add New Status)
          </a>

     
          <fluent-icon
           v-tooltip="'If you want to add new status, click on (+ Add New Status) link.'"
            icon="info"
            size="16"
            class="icon-color has-tooltip mt-0.5 info-icon-1"
          />
        </div>
      </div>
      <div class="flex justify-item">
        <div class="w-[90%]">
          <select v-model="statusId">
            <option v-for="state in status" :key="state.name" :value="state.id">
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="my-editor px-6">
        <label for="description" class="ml-2">Description</label>
        <div class="editor-ui rounded-md">
          <editor v-model="remark" :placeholder="'Add Remark...'" />
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-2 py-4 px-0 w-full">
      <div class="w-full flex justify-center gap-1">
        <woot-button class="button clear color cancel-btn" @click.prevent="onCancel">
          {{ $t('CONTACT_FORM.FORM.CANCEL') }}
        </woot-button>
        <woot-submit-button
          :disabled="!formHasChanged || statusId == null||progress"
          :loading="progress"
          class="button-color"
          :button-text="$t('CONTACT_FORM.FORM.SUBMIT')"
          @click="editStatus"
        />
      </div>
    </div>
  </woot-modal>
</template>
  
  <script>
import Editor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
export default {
  components: {
    Editor,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    status_id: {
      type: Number,
      default: null,
    },
    contactId: {
      type: Number,
      default: null,
    },
  },
  mixins: [alertMixin],
  watch: {
    status_id(newVal) {
      this.statusId = newVal;
      if(newVal!=null){
        this.getStatusData(newVal);
      }
      else{
        this.stateId=null;
        this.remark=''
      }
    },
  },
  data() {
    return {
      remark: '',
      statusId: null,
      progress: false,
      originalStatusId: null,  
      originalRemark: '',
    };
  },
  mounted() {
    this.setInitialValues();
    this.$store.dispatch('pipelines/getState');
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId', // Getter to fetch accountId from Vuex
    }),
    status() {
      return this.$store.getters['pipelines/getAllState'];
    },
    formHasChanged() {
      return (
        this.statusId !== this.originalStatusId || 
        this.remark !== this.originalRemark
      );
    },
  },
  methods: {
    navigateToAddNewStatus() {
      // Get the base URL dynamically from the current window location
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      
      // Ensure accountId is valid
      if (!this.accountId) {
        console.error("Account ID is undefined");
        return;
      }
      
      // Construct the URL dynamically using accountId
      const url = `${baseUrl}/app/accounts/${this.accountId}/settings/status?showAddStatus=true`;

      // Open the dynamically generated URL in a new tab
      this.onCancel();
      window.open(url, "_blank");
    },
    onCancel() {
      this.$emit('cancel');
    },
    async getStatusData(status_id) {
      const data={
        id: status_id,
        contact_id: this.contactId
      }
      const response = await this.$store.dispatch(
        'pipelines/UpdateStatus',
        data
      );
      console.log("Response",response);
      if (response.data.status_description !== null) {
        this.remark = response.data.status_description;
      }
      else{
        this.remark='';
      }

      this.setInitialValues();
    },
    setInitialValues() {
      this.originalStatusId = this.statusId;
      this.originalRemark = this.remark;
    },
    async editStatus() {
      this.progress=true;
      const data = {
        statusId: this.statusId,
        contactId: this.contactId,
        description: this.remark,
      };
      const response = await this.$store.dispatch(
        'pipelines/assignStatus',
        data
      );
      bus.$emit('on-assign');
      if (response.status == 200) {
        this.showAlert('Status Update Successfully', { success: true });
      }
      this.onCancel();
      this.progress=false;
    },
  },
};
</script>
  
<style lang="scss" scoped>
.justify-item {
  justify-content: space-around;
}
.button-color {
  background-color: #40518a;
}
.color {
  color: #40518a;
}
.editor-ui {
  border: 2px solid lightgrey;
  height: 200px;
  padding-left: 10px;
  margin: 0px 7px;
}
.cancel-btn{
  background-color: #fff;
  color: #40518a;
}
.cancel-btn:hover{
  background-color: #F9F9F9;
  color: #40518a;
}
/* Padding for Header */
.px-8 {
  padding-left: 1rem;
}

/* Styling for Add New State Link */
.add-new-state-link {
  position: absolute;
  top: 45px;
  left: 143px;
  color: #40518a;
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  text-decoration: none;
}

.add-new-state-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Styling for Info Icon */
.info-icon-1 {
  position: absolute;
  top: 45px;
  right: 308px;
  color: black;
}

.info-icon:hover {
  color: #40518a;
}
.info-color{
  color:black;
}
</style>