<template>
  <div>
    <div :class="getAlertClass">
      <div :class="getSnackbarTextClass">
        {{ message }}
      </div>
      <div v-if="action" class="ui-snackbar-action">
        <router-link v-if="action.type === 'link'" :to="action.to">
          {{ action.message }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String, default: '' },
    action: {
      type: Object,
      default: () => ({}), // Default to an empty object
    },
    showButton: Boolean,
    duration: {
      type: [String, Number],
      default: 3000,
    },
  },
  computed: {
    // Determines the class for the alert container
    getAlertClass() {
      if (this.action.success === true) {
        return 'success-alert';
      } else if (this.action.success === false) {
        return 'alert-error';
      } else {
        return 'ui-snackbar'; // Default case if success is undefined or missing
      }
    },
    // Determines the class for the snackbar text
    getSnackbarTextClass() {
      if (this.action.success === true || this.action.success === false) {
        return 'ui-snackbar-text'; // For both true and false
      } else {
        return 'ui-snackbar-white'; // Default for undefined or missing success
      }
    },
  },
  data() {
    return {
      toggleAfterTimeout: false,
    };
  },
};
</script>
