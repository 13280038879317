import { render, staticRenderFns } from "./NewCreateContact.vue?vue&type=template&id=707a8549&scoped=true&"
import script from "./NewCreateContact.vue?vue&type=script&lang=js&"
export * from "./NewCreateContact.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "707a8549",
  null
  
)

export default component.exports