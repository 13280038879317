<template>
  <footer
    v-if="isFooterVisible"
    class="bg-white dark:bg-slate-800 h-[50px] border-t border-solid border-slate-75 dark:border-slate-700/50 flex items-center justify-between py-0 px-4"
  >
    <div class="left-aligned-wrap">
      <div class="text-xs text-slate-600 dark:text-slate-200">
        <strong>{{ firstIndex }}</strong>
        - <strong>{{ lastIndex }}</strong> of
        <strong>{{ totalCount }}</strong> items
      </div>
    </div>
    <!-- <div class="right-aligned-wrap">
      <div
        v-if="totalCount"
        class="primary button-group pagination-button-group"
      >
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          class-names="goto-first"
          :is-disabled="hasFirstPage"
          @click="onFirstPage"
        >
          <fluent-icon icon="chevron-left" size="18" />
          <fluent-icon
            icon="chevron-left"
            size="18"
            :class="pageFooterIconClass"
          />
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :is-disabled="hasPrevPage"
          @click="onPrevPage"
        >
          <fluent-icon icon="chevron-left" size="18" />
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary button-color"
          @click.prevent
        >
          {{ currentPage }}
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :is-disabled="hasNextPage"
          @click="onNextPage"
        >
          <fluent-icon icon="chevron-right" size="18" />
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          class-names="goto-last"
          :is-disabled="hasLastPage"
          @click="onLastPage"
        >
          <fluent-icon icon="chevron-right" size="18" />
          <fluent-icon
            icon="chevron-right"
            size="18"
            :class="pageFooterIconClass"
          />
        </woot-button>
      </div>
    </div> -->
    <div class="right-aligned-wrap">
      <div
        v-if="totalPages > 1"
        class="primary button-group pagination-button-group"
      >
        <!-- First Page Button -->
        <!-- <woot-button
        size="small"
        variant="smooth"
        color-scheme="secondary"
        :is-disabled="hasFirstPage"
        @click="onFirstPage"
      >
        First
      </woot-button>

      Previous Page Button --
      <woot-button
        size="small"
        variant="smooth"
        color-scheme="secondary"
        :is-disabled="hasFirstPage"
        @click="onPrevPage"
      >
        Prev
      </woot-button> -->

        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          class-names="goto-first"
          :is-disabled="hasFirstPage"
          @click="onFirstPage"
        >
          <fluent-icon icon="chevron-left" size="18" />
          <fluent-icon
            icon="chevron-left"
            size="18"
            :class="pageFooterIconClass"
          />
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :is-disabled="hasFirstPage"
          @click="onPrevPage"
        >
          <fluent-icon icon="chevron-left" size="18" />
        </woot-button>

        <!-- Page Numbers with Ellipsis -->
        <woot-button
          v-for="page in visiblePages"
          :key="page"
          size="small"
          variant="smooth"
          :class="{ 'is-active': page === currentPage, 'not-active': page !== currentPage }"
          @click="goToPage(page)"
        >
          {{ page }}
        </woot-button>

        <!-- Next Page Button -->
        <!-- <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :is-disabled="hasLastPage"
          @click="onNextPage"
        >
          Next
        </woot-button>

        Last Page Button
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :is-disabled="hasLastPage"
          @click="onLastPage"
        >
          Last
        </woot-button> -->
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :is-disabled="hasLastPage"
          @click="onNextPage"
        >
          <fluent-icon icon="chevron-right" size="18" />
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          class-names="goto-last"
          :is-disabled="hasLastPage"
          @click="onLastPage"
        >
          <fluent-icon icon="chevron-right" size="18" />
          <fluent-icon
            icon="chevron-right"
            size="18"
            :class="pageFooterIconClass"
          />
        </woot-button>
      </div>
    </div>
  </footer>
</template>

<script>
import rtlMixin from 'shared/mixins/rtlMixin';

export default {
  components: {},
  mixins: [rtlMixin],
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  // computed: {
  //   pageFooterIconClass() {
  //     return this.isRTLView
  //       ? 'margin-right-minus-slab'
  //       : 'margin-left-minus-slab';
  //   },
  //   isFooterVisible() {
  //     return this.totalCount && !(this.firstIndex > this.totalCount);
  //   },
  //   firstIndex() {
  //     return this.pageSize * (this.currentPage - 1) + 1;
  //   },
  //   lastIndex() {
  //     return Math.min(this.totalCount, this.pageSize * this.currentPage);
  //   },
  //   searchButtonClass() {
  //     return this.searchQuery !== '' ? 'show' : '';
  //   },
  //   hasLastPage() {
  //     return this.currentPage === Math.ceil(this.totalCount / this.pageSize);
  //   },
  //   hasFirstPage() {
  //     return this.currentPage === 1;
  //   },
  //   hasNextPage() {
  //     return this.currentPage === Math.ceil(this.totalCount / this.pageSize);
  //   },
  //   hasPrevPage() {
  //     return this.currentPage === 1;
  //   },
  // },
  computed: {
    pageFooterIconClass() {
      return this.isRTLView
        ? 'margin-right-minus-slab'
        : 'margin-left-minus-slab';
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.pageSize);
    },
    isFooterVisible() {
      return this.totalCount && !(this.firstIndex > this.totalCount);
    },
    firstIndex() {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    lastIndex() {
      return Math.min(this.totalCount, this.pageSize * this.currentPage);
    },
    visiblePages() {
      const pages = [];
      const total = this.totalPages;
      const current = this.currentPage;

      // If total pages are <= 4, show all pages
      if (total <= 4) {
        return Array.from({ length: total }, (_, i) => i + 1);
      }

      // First pages view: 1 2 3 4 ...
      if (current <= 3) {
        for (let i = 1; i <= 4; i++) pages.push(i);
        pages.push('...');
      }
      // Last pages view: ... 3 4 5
      else if (current >= total - 2) {
        pages.push('...');
        for (let i = total - 3; i <= total; i++) pages.push(i);
      }
      // Middle pages view: ... 2 3 4 ...
      else {
        pages.push('...');
        for (let i = current - 1; i <= current + 1; i++) pages.push(i);
        pages.push('...');
      }

      return pages;
    },
    hasFirstPage() {
      return this.currentPage === 1;
    },
    hasLastPage() {
      return this.currentPage === this.totalPages;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
  },
  methods: {
    goToPage(page) {
      if (page === '...') return; // Ignore clicks on ellipsis
      this.$emit('page-change', page);
    },
    onFirstPage() {
      this.$emit('page-change', 1);
    },
    onPrevPage() {
      if (this.currentPage > 1) {
        this.$emit('page-change', this.currentPage - 1);
      }
    },
    onNextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('page-change', this.currentPage + 1);
      }
    },
    onLastPage() {
      this.$emit('page-change', this.totalPages);
    },
  },
  // methods: {
  //   onNextPage() {
  //     if (this.hasNextPage) {
  //       return;
  //     }
  //     const newPage = this.currentPage + 1;
  //     this.onPageChange(newPage);
  //   },
  //   onPrevPage() {
  //     if (this.hasPrevPage) {
  //       return;
  //     }
  //     const newPage = this.currentPage - 1;
  //     this.onPageChange(newPage);
  //   },
  //   onFirstPage() {
  //     if (this.hasFirstPage) {
  //       return;
  //     }
  //     const newPage = 1;
  //     this.onPageChange(newPage);
  //   },
  //   onLastPage() {
  //     if (this.hasLastPage) {
  //       return;
  //     }
  //     const newPage = Math.ceil(this.totalCount / this.pageSize);
  //     this.onPageChange(newPage);
  //   },
  //   onPageChange(page) {
  //     this.$emit('page-change', page);
  //   },
  // },
  // methods: {
  //   goToPage(page) {
  //     if (page === '...') return; // Ignore clicks on ellipsis
  //     this.$emit('page-change', page);
  //   },
  //   onFirstPage() {
  //     this.$emit('page-change', 1);
  //   },
  //   onPrevPage() {
  //     if (this.currentPage > 1) {
  //       this.$emit('page-change', this.currentPage - 1);
  //     }
  //   },
  //   onNextPage() {
  //     if (this.currentPage < this.pageSize) {
  //       this.$emit('page-change', this.currentPage + 1);
  //     }
  //   },
  //   onLastPage() {
  //     this.$emit('page-change', this.pageSize);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.goto-first,
.goto-last {
  i:last-child {
    @apply -ml-1;
  }
}
.is-active {
  background: #40518a !important;
  color: #fff !important;
  border-radius: 50%;
}
 .not-active {
  background-color: #fff !important;
  color: #000 !important;
}
body.dark .not-active {
  background-color: hsl(198 6.6% 15.8% / 1) !important;
  color: #fff !important;
}
</style>
