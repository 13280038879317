<template>
  <woot-modal :show.sync="show" :on-close="onCancel" class="custom-modal">
    <div class="h-auto overflow-auto flex flex-col">
     
        <!-- Header Section -->
        <div class="flex items-center justify-between px-4 py-2">
        <div class="flex items-center gap-2">
         
          <woot-modal-header :header-title="'Edit Board'" />
          
       
          <a
            href="#"
            @click.prevent="navigateToAddNewBoard"
            class="text-blue-500 text-sm font-medium hover:underline add-new-state-link"
          >
            (+ Add New Board)
          </a>

     
          <fluent-icon
           v-tooltip="'If you want to add new board, click on (+ Add New Board) link.'"
            icon="info"
            size="16"
            class="info-color has-tooltip mt-0.5 info-icon-3"
          />
        </div>
      </div>
      <div class="flex justify-item">
        <div class="w-[90%]">
          <select v-model="boardId">
            <option v-for="board in boards" :key="board.name" :value="board.id">
              {{ board.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- <div class="my-editor px-6">
        <div class="editor-ui rounded-md">
          <editor v-model="remark" :placeholder="'Add Remark...'" />
        </div>
      </div> -->
    </div>
    <div class="flex flex-row gap-2 py-4 px-0 w-full">
      <div class="w-full flex justify-center gap-1">
        <woot-button class="cancel-btn" @click.prevent="onCancel">
            {{ $t('CONTACT_FORM.FORM.CANCEL') }}
          </woot-button>
        <woot-submit-button
          :disabled="!formHasChanged || boardId == null||progress"
          :loading="progress"
          class="button-color"
          :button-text="$t('CONTACT_FORM.FORM.SUBMIT')"
          @click="createBoard"
        />
      </div>
    </div>
  </woot-modal>
</template>
    
    <script>
import Editor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
export default {
  components: {
    Editor,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    board_id: {
      type: Number,
      default: null,
    },
    contactId: {
      type: Number,
      default: null,
    },
  },
  mixins: [alertMixin],
  watch: {
    board_id(newVal) {
      this.boardId = newVal;
      // this.getBoardData(newVal);
      if(newVal!=null){
        this.getBoardData(newVal);
      }
      else{
        this.boardId=null;
      }
    },
  },
  data() {
    return {
      boardId: null,
      progress: false,
      originalBoardId: null,  
    };
  },
  mounted() {
    this.setInitialValues();
    this.$store.dispatch('pipelines/getBoards');
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId', // Getter to fetch accountId from Vuex
    }),
    boards() {
      return this.$store.getters['pipelines/getAllBoards'];
    },
    formHasChanged() {
      return this.boardId !== this.originalBoardId; 
    },
  },
  methods: {
    navigateToAddNewBoard() {
      // Get the base URL dynamically from the current window location
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      
      // Ensure accountId is valid
      if (!this.accountId) {
        console.error("Account ID is undefined");
        return;
      }
      
      // Construct the URL dynamically using accountId
      const url = `${baseUrl}/app/accounts/${this.accountId}/settings/board?showAddBoard=true`;

      // Open the dynamically generated URL in a new tab
      this.onCancel();
      window.open(url, "_blank");
    },
    onCancel() {
      this.$emit('cancel');
    },
    async getBoardData(board_id) {
      console.log("getBoardData called");
      const response = await this.$store.dispatch(
        'contacts/getBoard',
        board_id
      );
      console.log('Response', response);
      this.setInitialValues();
    },
    setInitialValues() {
      this.originalBoardId = this.boardId;
    },
    async createBoard() {
      this.progress=true;
      const data = {
        boardId: this.boardId,
        contactId: this.contactId,
        description: this.remark,
      };
      console.log('Data::::', data);
      const response = await this.$store.dispatch('contacts/assignBoard', data);
      bus.$emit('on-assign');
      if (response.status == 200) {
        this.showAlert('Board Update Successfully', { success: true });
      }
      this.onCancel();
      this.progress=false;
    },
    backUrl() {
      console.log('account id :- ' + this.$route.params.accountId);
      // return `/app/accounts/${this.$route.params.accountId}/reports/overview`;
      this.$router.push({ name: 'settings_account_reports' });
    },
  },
};
</script>
    
<style lang="scss" scoped>
.justify-item {
  justify-content: space-around;
}
.button-color {
  background-color: #40518a;
}
.color {
  color: #40518a;
}
.editor-ui {
  border: 2px solid lightgrey;
  margin: 0px 7px;
  height: 200px;
  padding-left: 10px;
}
.cancel-btn{
    background-color: #fff;
    color: #40518a;
  }
  .cancel-btn:hover{
    background-color: #F9F9F9;
    color: #40518a;
  }
  /* Padding for Header */
.px-8 {
  padding-left: 1rem;
}

/* Styling for Add New State Link */
.add-new-state-link {
  position: absolute;
  top: 45px;
  left: 137px;
  color: #40518a;
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  text-decoration: none;
}

.add-new-state-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Styling for Info Icon */
.info-icon-3 {
  position: absolute;
  top: 45px;
  right: 316px;
  // color: #40518a;
}

.info-icon:hover {
  color: #40518a;
}
.info-color{
  color:black;
}
</style>