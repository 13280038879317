import { frontendURL } from '../../../../helper/URLHelper';

const pipelines = accountId => ({
  parentNav: 'pipeline',
  routes: [
    'pipeline'
  ],
  menuItems: [
    {
      icon: 'pipeline',
      key: 'pipeline',
      label: 'BOARDS',
      toState: frontendURL(`accounts/${accountId}/pipelines`),
      toStateName: 'pipeline',
    },
  ],
});

export default pipelines;
