<template>
  <header
    class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
  >
    <div class="flex justify-between w-full py-2 px-4">
      <div class="flex items-center justify-center max-w-full min-w-[6.25rem]">
        <!-- <woot-sidemenu-icon /> -->
        <div class="back-icon" @click="backUrl">
          <fluent-icon
            v-tooltip="'Back'"
            icon="back"
            :viewBox="'0 0 52 52'"
            size="14"
            class="mt-0.5 back-contact cursor-pointer"
          />
        </div>
        <h1
          class="m-0 text-xl text-slate-900 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis my-0 mx-2"
        >
          {{ headerTitle }}
        </h1>
      </div>
      <div class="flex gap-2 w-[90%] justify-center">
        <!-- <woot-button
          v-if="isAdmin"
          color-scheme="secondary"
          v-tooltip="'import'"
          icon="import"
          class="clear  clear1"
          @click="toggleImport"
        />

        <woot-button
          v-if="isAdmin"
          color-scheme="secondary"
          v-tooltip="'export'"
          icon="export"
          class="clear  clear1"
          @click="submitExport"
        /> -->
      <!--  <woot-button
          v-if="isAdmin"
          color-scheme="info"
          icon="upload"
          class="clear"
          @click="toggleImport"
        >
          {{ $t('IMPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>

        <woot-button
          v-if="isAdmin"
          color-scheme="info"
          icon="download"
          class="clear"
          @click="submitExport"
        >
          {{ $t('EXPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button> -->
        <div class="width-35 flex items-center relative mx-2 search-wrap">
          <div class="cursor-pointer flex items-center absolute h-full right-3" @click="submitSearch">
            <fluent-icon
              icon="search"
              class="h-5 leading-9 text-sm text-slate-400 dark:text-slate-200"
            />
          </div>
          <input
            type="text"
            :placeholder="$t('CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER')"
            class="contact-search border-slate-100 dark:border-slate-600"
            :value="searchQuery"
            @keyup.enter="submitSearch"
            @input="inputSearch"
          />
          <!-- <woot-button
            :is-loading="false"
            class="clear"
            :class-names="searchButtonClass"
            @click="submitSearch"
          >
            {{ $t('CONTACTS_PAGE.SEARCH_BUTTON') }}
          </woot-button> -->
        </div>
        <div v-if="hasActiveSegments" class="flex gap-2">
          <woot-button
            class="clear"
            color-scheme="secondary"
            icon="edit"
            @click="onToggleEditSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_EDIT') }}
          </woot-button>
          <woot-button
            class="clear"
            color-scheme="alert"
            icon="delete"
            @click="onToggleDeleteSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_DELETE') }}
          </woot-button>
        </div>
        <div>
          <SelectStatus
            :status="status"
            placeholder="Status"
            :open="openDropdown === 'status'"
            @input="handleDropdownstatus"
            @toggle="toggleDropdown('status')"
          />
          <!-- <add-more-board :show="showMoreBoard" @close="hideMoreboard" /> -->
        </div>
        <div>
          <SelectState
            :states="states"
            placeholder="State"
            :open="openDropdown === 'state'"
            @input="handleDropdownState"
            @toggle="toggleDropdown('state')"
          />
          <!-- <add-more-state :show="showMoreState" @close="hideMorestate" /> -->
        </div>
        <div v-if="!hasActiveSegments" class="relative">
          <div
            v-if="hasAppliedFilters"
            class="absolute w-2 h-2 rounded-full top-1 right-3 bg-slate-500 dark:bg-slate-500"
          />
          <woot-button
            class="clear [&>span]:hidden xs:[&>span]:block"
            color-scheme="secondary"
            data-testid="create-new-contact"
            icon="filter"
            v-tooltip="'Filter'"
            @click="toggleFilter"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS') }}
          </woot-button>
        </div>
        <woot-button
          v-if="hasAppliedFilters && !hasActiveSegments"
          class="clear"
          color-scheme="alert"
          variant="clear"
          icon="save"
          @click="onToggleSegmentsModal"
        >
          {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_SAVE') }}
        </woot-button>
        <woot-button
          class="import-clear m-w-140"
          color-scheme="success"
          icon="person-add"
          data-testid="create-new-contact"
          @click="toggleCreate"
        >
          {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
        </woot-button>
        <woot-button
          v-if="isAdmin"
          color-scheme="info"
          icon="upload"
          class="clear"
          @click="toggleImport"
        >
          {{ $t('IMPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>

        <woot-button
          v-if="isAdmin"
          color-scheme="info"
          icon="download"
          class="clear"
          @click="submitExport"
        >
          {{ $t('EXPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>
      <!--  <div v-if="!hasActiveSegments" class="relative">
            <div
              v-if="hasAppliedFilters"
              class="absolute w-2 h-2 rounded-full top-1 right-3 bg-slate-500 dark:bg-slate-500"
            />
            <woot-button
              class="clear [&>span]:hidden xs:[&>span]:block"
              color-scheme="secondary"
              data-testid="create-new-contact"
              icon="filter"
              v-tooltip="'Filter'"
              @click="toggleFilter"
            >
              {{ $t('CONTACTS_PAGE.FILTER_CONTACTS') }}
            </woot-button>
          </div> -->
        <!-- <woot-button
          class="plus-icon"
          icon="add"
          color-scheme="secondary"
          @click="createFilter"
        > -->
        <!-- </woot-button> -->
        <add-state :show="showMoreState" @cancel="hideState" />
        <add-status :show="showMoreStatus" @cancel="hideStatus" />
 
        <woot-button 
         v-if="isAdmin"
         class="import-clear"
          @click="toggleSendReport"
         >
        {{ $t('EXPORT_CONTACTS.REPORT') }}
        </woot-button>
            <!-- Conditionally render SendReport component -->
   <SendReport v-if="showSendReport" :show="showSendReport" @close="toggleSendReport" />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import AddState from './AddState.vue';
import AddStatus from './AddStatus.vue';
import SelectState from './StateDropdownContact.vue';
import SelectStatus from './StatusDropdownContact';
import SendReport from './SendReport.vue';

export default {
  mixins: [adminMixin],
  components: {
    AddState,
    AddStatus,
    SelectState,
    SelectStatus,
    SendReport,
  },
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      default: '',
    },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      showSendReport: false, // Controls visibility of SendReport
      showCreateModal: false,
      showImportModal: false,
      selectedStatus: '',
      selectedState: '',
      showMoreState: false,
      showMoreStatus: false,
      openDropdown: null,
    };
  },
  mounted() {
    this.$store.dispatch('pipelines/getState');
    this.$store.dispatch('pipelines/getStatus');
  },
  computed: {
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    ...mapGetters({
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
    }),
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    hasActiveSegments() {
      return this.segmentsId !== 0;
    },
    states() {
      return [{ id: '', name: 'All' }, ...this.$store.getters['pipelines/getAllStatus']];
    },
    status() {
      return [{ id: '', name: 'All' },...this.$store.getters['pipelines/getAllState']];
    },
  },
  methods: {
    toggleSendReport() {
      this.showSendReport = !this.showSendReport;
    },
    onToggleSegmentsModal() {
      this.$emit('on-toggle-save-filter');
    },
    onToggleEditSegmentsModal() {
      this.$emit('on-toggle-edit-filter');
    },
    onToggleDeleteSegmentsModal() {
      this.$emit('on-toggle-delete-filter');
    },
    toggleCreate() {
      this.$emit('on-toggle-create');
    },
    createFilter() {
      this.$emit('on-create-filter');
    },
    toggleFilter() {
      this.$emit('on-toggle-filter');
    },
    toggleImport() {
      this.$emit('on-toggle-import');
    },
    submitExport() {
      this.$emit('on-export-submit');
    },
    submitSearch() {
      const trimmedQuery = this.searchQuery.trim();
      if (!trimmedQuery) {
         return;
       }
      this.$emit('on-search-submit');
    },
    inputSearch(event) {
      this.$emit('on-input-search', event);
    },
    backUrl() {
      console.log('account id :- ' + this.$route.params.accountId);
      // return `/app/accounts/${this.$route.params.accountId}/reports/overview`;
      this.$router.push({ name: 'settings_account_reports' });
    },
    handleDropdownState(selectedId) {
      this.$emit('on-state-search', selectedId);
    },
    handleDropdownstatus(selectedId) {
      this.$emit('on-status-search', selectedId);
    },
    hideStatus() {
      this.showMoreStatus = false;
    },
    hideState() {
      this.showMoreState = false;
    },
    toggleDropdown(dropdownName) {
      bus.$emit('toggle-contact-heading-dropdown');
      this.openDropdown =
        this.openDropdown === dropdownName ? null : dropdownName;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrap {
  .contact-search {
    @apply pl-3 pr-[3.75rem] text-sm w-full h-[2.8rem] m-0;
  }

  .button {
    transition: transform 100ms linear;
    @apply ml-2 h-8 right-1 absolute py-0 px-2 opacity-0 -translate-x-px invisible;
  }

  .button.show {
    @apply opacity-100 translate-x-0 visible;
  }
}
.width-35 {
  width: 35%;
}
.import-clear {
  background-color: #40518a !important;
  font-size: 14px;
  padding: 20px 10px;
}
.m-w-140 {
  min-width: 140px;
}
.plus-icon {
  background-color: #40518a;
}
.grey-color {
  color: #989898;
}
.back-contact {
  /*color: #2B2B2B*/
}
.text-slate-901 {
  color: #464646 !important;
}
.clear1 {
  background-color: transparent; // Ensure there's no background by default
  color: #989898 !important; // Keep the original color
  transition: color 0.2s ease;
  &:hover {
    color: #0e1f57 !important; // Change color to red on hover
    background-color: transparent !important; // Ensure no background on hover
  }
}
</style>
