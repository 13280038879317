import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';

export default {
  methods: {
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    messageTimestamp(time, dateFormat = 'MMM d, yyyy') {
      const messageTime = fromUnixTime(time);
      const now = new Date();
      const messageDate = format(messageTime, dateFormat);
      if (!isSameYear(messageTime, now)) {
        return format(messageTime, 'LLL d y, h:mm a');
      }
      return messageDate;
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: true });
    },
    dateFormat(time, dateFormat = 'MMM d, yyyy') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    // shortTimestamp(time) {
    //   const convertToShortTime = time
    //     .replace(/about|over|almost|/g, '')
    //     .replace('less than a minute ago', 'now')
    //     .replace(' minute ago', ' mins')
    //     .replace(' minutes ago', ' mins')
    //     .replace('a minute ago', ' min')
    //     .replace('an hour ago', ' hour')
    //     .replace(' hour ago', ' hours')
    //     .replace(' hours ago', ' hours')
    //     .replace(' day ago', ' days ago')
    //     .replace('a day ago', ' day ago')
    //     .replace(' days ago', ' days ago')
    //     .replace('a month ago', ' mon ago')
    //     .replace(' months ago', ' mon ago')
    //     .replace(' month ago', ' mon ago')
    //     .replace('a year ago', ' year ago')
    //     .replace(' year ago', ' years ago')
    //     .replace(' years ago', ' years ago');
    //   return convertToShortTime;
    // },
    shortTimestamp(time) {
      const convertToShortTime = time
        .replace(/about|over|almost|/g, '')
        .replace('less than a minute ago', 'now')
        .replace(/\b1 minute ago\b/, '1 min ago')
        .replace(/\b(\d+) minutes ago\b/, '$1 mins ago')
        .replace('a minute ago', '1 min ago')
        .replace('an hour ago', '1 hour ago')
        .replace(/\b(\d+) hours ago\b/, '$1 hours ago')
        .replace('1 hour ago', '1 hour ago')
        .replace('a day ago', '1 day ago')
        .replace(/\b(\d+) days ago\b/, '$1 days ago')
        .replace('a month ago', '1 mon ago')
        .replace(/\b(\d+) months ago\b/, '$1 mons ago')
        .replace('1 month ago', '1 mon ago')
        .replace('a year ago', '1 year ago')
        .replace(/\b(\d+) years ago\b/, '$1 years ago')
        .replace('1 year ago', '1 year ago');
      return convertToShortTime;
    },
    
  },
};
