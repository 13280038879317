export default {
  computed: {
    getLabelTitleErrorPipeline() {
      let errorMessage = '';
      if (!this.$v.name.$error) {
        errorMessage = '';
      } else if (!this.$v.name.required) {
        errorMessage = this.$t('PIPELINES.REQUIRED_ERROR');
      } else if (!this.$v.name.minLength) {
        errorMessage = this.$t('PIPELINES.MINIMUM_LENGTH_ERROR');
      }else if (!this.$v.name.maxLength) {
        errorMessage = this.$t('PIPELINES.MAXIMUM_LENGTH_ERROR');
      }
      return errorMessage;
    },
    getAddMoreState(){
      let errorMessage = '';
      if (!this.$v.name.$error) {
        errorMessage = '';
      } else if (!this.$v.name.required) {
        errorMessage = this.$t('PIPELINES.REQUIRED_STATE');
      } else if (!this.$v.name.minLength) {
        errorMessage = this.$t('PIPELINES.MINIMUM_LENGTH_ERROR');
      }else if (!this.$v.name.maxLength) {
        errorMessage = this.$t('PIPELINES.MAXIMUM_LENGTH_ERROR');
      }
      return errorMessage;
    },
    getAddMoreBoard(){
      let errorMessage = '';
      if (!this.$v.name.$error) {
        errorMessage = '';
      } else if (!this.$v.name.required) {
        errorMessage = this.$t('PIPELINES.REQUIRED_BOARD');
      } else if (!this.$v.name.minLength) {
        errorMessage = this.$t('PIPELINES.MINIMUM_LENGTH_ERROR');
      }else if (!this.$v.name.maxLength) {
        errorMessage = this.$t('PIPELINES.MAXIMUM_LENGTH_ERROR');
      }
      return errorMessage;
    },
    getDescriptionError() {
      let errorMessage = '';
      if (!this.$v.description.$error) {
        errorMessage = '';
      } else if (!this.$v.description.minLength) {
        errorMessage = this.$t('PIPELINES.MINIMUM_LENGTH_ERROR_DESC');
      } else if (!this.$v.description.maxLength) {
        errorMessage = this.$t('PIPELINES.MAXIMUM_LENGTH_ERROR_DESC');
      }
      return errorMessage;
    },
    isDescriptionValid() {
      return this.description.trim() !== '';
    },
    isDescriptionEmpty() {
      return this.description.length === 0;
    },
    isSubmitEnabled() {
      return (
        !this.$v.name.$invalid &&
        // (this.isDescriptionEmpty || this.isDescriptionValid) &&
        !this.$v.description.$invalid
      );
    },
  },
};
