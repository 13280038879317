<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('LABEL_MGMT.ADD.TITLE')"
      :header-content="$t('LABEL_MGMT.ADD.DESC')"
    />
    <form class="mx-0 flex flex-wrap" @submit.prevent="addLabel">
      <!-- <woot-input
        v-model.trim="title"
        :class="{ error: $v.title.$error }"
        class="w-full label-name--input"
        :label="$t('LABEL_MGMT.FORM.NAME.LABEL')"
        :placeholder="$t('LABEL_MGMT.FORM.NAME.PLACEHOLDER')"
        :error="getLabelTitleErrorMessage"
        data-testid="label-title"
        @input="$v.title.$touch"
      /> -->
      <label>
        {{ $t('LABEL_MGMT.FORM.NAME.LABEL') }}
        <span class="text-red-500">*</span>
      </label>
      <woot-input
        v-model.trim="title"
        :class="{ error: $v.title.$error }"
        class="w-full label-name--input"
        :placeholder="$t('LABEL_MGMT.FORM.NAME.PLACEHOLDER')"
        :error="getLabelTitleErrorMessage"
        data-testid="label-title"
        @input="$v.title.$touch"
      />

      <woot-input
        v-model.trim="description"
        :class="{ error: $v.description.$error }"
        class="w-full"
        :label="$t('LABEL_MGMT.FORM.DESCRIPTION.LABEL')"
        :placeholder="$t('LABEL_MGMT.FORM.DESCRIPTION.PLACEHOLDER')"
        data-testid="label-description"
        @input="$v.description.$touch"
      />

      <div class="demo-modal w-full" v-if="forAutomation">
        <div class="w-full">
          <div class="demo-data" v-for="(data, index) in dataList" :key="index">
            <woot-input
              v-model.trim="data.trigger_before"
              :class="{ error: $v.triggeredBefore.$error }"
              class="triggeredBefore"
              :label="$t('LABEL_MGMT.FORM.TRIGGEREDBEFORE.LABEL')"
              :placeholder="$t('LABEL_MGMT.FORM.TRIGGEREDBEFORE.PLACEHOLDER')"
              data-testid="label-triggeredBefore"
              @input="$v.triggeredBefore.$touch"
            />

            <div class="macro">
              <label :class="{ error: $v.macro.$error }">
                {{ $t('LABEL_MGMT.FORM.MACRO.LABEL') }}
                <select v-model="data.macro_id">
                  <option
                    v-for="record in records"
                    :key="record.id"
                    :value="record.id"
                  >
                    {{ record.name }}
                  </option>
                </select>
              </label>
            </div>

            <div class="macro">
              <label :class="{ error: $v.macro.$error }">
                {{ $t('LABEL_MGMT.FORM.AUTOMATION.LABEL') }}
                <select v-model="data.is_before">
                  <option :value="true">
                    {{ $t('LABEL_MGMT.FORM.AUTOMATION.TRUE') }}
                  </option>
                  <option :value="false">
                    {{ $t('LABEL_MGMT.FORM.AUTOMATION.FALSE') }}
                  </option>
                </select>
              </label>
            </div>

            <div class="delete-button">
              <woot-button
                v-tooltip="$t('DELETE_CONTACT.BUTTON_LABEL')"
                icon="delete"
                variant="smooth"
                size="small"
                color-scheme="alert"
                @click="removeData(index)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="add-button" @click="addData" v-if="forAutomation">
        {{ $t('LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.ADDLABEL') }}
      </div>

      <div class="w-full">
        <label>
          {{ $t('LABEL_MGMT.FORM.COLOR.LABEL') }}
          <woot-color-picker v-model="color" />
        </label>
      </div>
      <div class="w-full">
        <input v-model="showOnSidebar" type="checkbox" :value="true" />
        <label for="conversation_creation">
          {{ $t('LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.LABEL') }}
        </label>
      </div>

      <div class="w-full">
        <input v-model="forAutomation" type="checkbox" :value="true" />
        <label for="conversation_creation"
          >{{ $t('LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.AUTOMATION') }}
        </label>
      </div>

      <div class="flex justify-end items-center py-2 px-0 gap-2 w-full">
        <woot-button
          :is-disabled="$v.title.$invalid || uiFlags.isCreating"
          :is-loading="uiFlags.isCreating"
          data-testid="label-submit"
        >
          {{ $t('LABEL_MGMT.FORM.CREATE') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('LABEL_MGMT.FORM.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import validationMixin from './validationMixin';
import { mapGetters } from 'vuex';
import validations from './validations';
import { getRandomColor } from 'dashboard/helper/labelColor';

export default {
  mixins: [alertMixin, validationMixin],
  props: {
    prefillTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      color: '#000',
      description: '',
      dataList: [
        {
          macro_id: '',
          trigger_before: '',
        },
      ],
      title: '',
      showOnSidebar: true,
      forAutomation: false,
      selectedValue: 'Before',
    };
  },
  validations,
  computed: {
    ...mapGetters({
      records: ['macros/getMacros'],
      uiFlags: 'labels/getUIFlags',
    }),
  },

  mounted() {
    this.$store.dispatch('macros/get');
    this.color = getRandomColor();
    this.title = this.prefillTitle.toLowerCase();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async addLabel() {
      try {
        await this.$store.dispatch('labels/create', {
          color: this.color,
          description: this.description,
          title: this.title.toLowerCase(),
          show_on_sidebar: this.showOnSidebar,
          label_triggers_attributes: this.dataList,
          automation: this.forAutomation,
        });
        this.showAlert(this.$t('LABEL_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    addData() {
      this.dataList.push({
        triggeredBefore: '',
        macro_id: '',
        is_before: false,
      });
    },
    removeData(index) {
      this.dataList.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
// Label API supports only lowercase letters
.label-name--input {
  ::v-deep {
    input {
      @apply lowercase;
    }
  }
}
.demo-modal {
  display: flex;
  justify-content: space-between;
}
.demo-data {
  // width: 80%;
  display: flex;
  justify-content: space-between;
}
.delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  font-size: 10px;
  background: aliceblue;
  font-weight: 500;
  border-radius: 5px;
}
.macro {
  width: 25%;
}
.triggeredBefore {
  width: 33%;
}
</style>
