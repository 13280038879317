<template>
  <woot-modal :show="show" :on-close="onClose">
    <woot-modal-header
      :header-title="$t('EXPORT_CONTACTS.REPORT_MODAL_TITLE')"
    />
    <p class="modal-description">
      {{ $t('EXPORT_CONTACTS.REPORT_MODAL_DESCRIPTION') }}
    </p>
    <div class="remainder-table">
      <div class="remainder-modal">
        <div class="remainder-input date-time">
          <div class="date-time-input">
            <label for="from-date">
              {{ $t('EXPORT_CONTACTS.FROM_DATE') }}
              <span class="text-red-500">*</span>
              <input
                type="date"
                id="from-date"
                v-model="reportDateRange.from"
                :max="maxFromDate"
              />
            </label>
            <label for="to-date">
              {{ $t('EXPORT_CONTACTS.TO_DATE') }}
              <span class="text-red-500">*</span>
              <input
                type="date"
                id="to-date"
                v-model="reportDateRange.to"
                :min="minToDate"
                :max="maxToDate"
              />
            </label>
          </div>
        </div>
      </div>
      <div class="flex justify-end items-center pb-6 pt-2 gap-2 w-full">
        <woot-submit-button
          :disabled="reportDateRange.from.length==0||reportDateRange.to.length==0||uiFlags.isCreating"
          :loading="uiFlags.isCreating"
          class="button-color"
          :button-text="'Send Report'"
          @click="confirmSendReport"
        />

        <woot-button class="button clear cancel-btn" @click.prevent="onClose">
          {{ $t('LABEL_MGMT.FORM.CANCEL') }}
        </woot-button>
      </div>
    </div>
  </woot-modal>
</template>
  
  
   <script>
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reportDateRange: {
        from: '',
        to: '',
      },
    };
  },
  components: {
    WootDateTimePicker,
  },
  mixins: [alertMixin],
  computed: {
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
      uiFlags: 'contacts/getUIFlags',
    }),
    maxFromDate() {
      const today = new Date();
      return today.toISOString().split('T')[0];
    },
    minToDate() {
      const fromDate = this.reportDateRange.from
        ? new Date(this.reportDateRange.from)
        : new Date();
      fromDate.setDate(fromDate.getDate() + 1);
      return fromDate.toISOString().split('T')[0];
    },
    maxToDate() {
      const today = new Date();
      return today.toISOString().split('T')[0];
    },
  },
  methods: {
    async confirmSendReport() {
      const data = {
        from_date: this.reportDateRange.from,
        to_date: this.reportDateRange.to,
        currentUserId: this.currentUserId,
      };
      const response = await this.$store.dispatch('contacts/sendReport', data);
      this.showAlert(response.message,{success:response.success});
      this.onClose();
      
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
    <style lang="scss" scoped>
.remainder-table {
  margin: 10px 35px;
}

.remainder-modal {
  display: flex;
}

.remainder-input {
  width: 100%;
}
.remainder-text {
  width: 20%;
  font-size: 14px;
  font-weight: 500;
}
.remainder-text-select {
  padding: 5% 0px;
}
.input-textarea textarea {
  resize: none;
}
.date-time {
  display: flex;
  justify-content: space-between;
}
.date,
.time {
  margin: 0px 2%;
}
.remainder-time {
  display: flex;
  justify-content: inherit;
  width: 35%;
}
.date-time-input {
  width: 100%;
}
.footer {
  @apply mt-6 flex justify-end;
}
.remainder-time select {
  width: 66%;
}
.remainder-footer {
  flex-direction: inherit;
}
.labels-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.automation-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.label-automation {
  padding-top: 10px;
  justify-content: space-between;
}
.labels-text {
  width: 30%;
}
.label-input {
  width: 50%;
}
.automation-text {
  width: 55%;
}
.automation-input {
  width: 50%;
}
.overlay {
  position: fixed;
  margin: auto;
  left: 50vw;
  width: 250px;
}
.success-popup {
  background-color: #44ce4b;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.date-time-picker-modal {
  width: 90%;
  padding-top: 2%;
}
.cancel-btn {
  background-color: #fff;
  color: #40518a;
}
.cancel-btn:hover {
  background-color: #f9f9f9;
  color: #40518a;
}
.button-color {
  background-color: #40518a;
}
.text-center {
  text-align: center;
}
.mb-4 {
  margin-bottom: 1rem;
}
.modal-description {
  margin: 0 auto 1rem;
  text-align: left;
  max-width: 89%; /* Align with modal width */
  font-size: 14px; /* Adjust font size for alignment */
  color: #4a4a4a; /* Adjust to match modal header text color */
}
</style>
    