<template>
  <section
    class="contacts-table-wrap bg-white dark:bg-slate-900 flex-1 h-full overflow-hidden"
  >
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 7.125rem)"
      scroll-width="187rem"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />
    <modal
      v-if="contactToDelete.id"
      :show="isModalVisible"
      :contact="contactToDelete"
      @cancel="closeModal"
    />
    <!-- <conversation-modal :visible.sync="isModalVisible"/> -->

    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('CONTACTS_PAGE.LIST.404')"
    />
    <empty-state
      v-else-if="!isLoading && !contacts.length"
      :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')"
    />
    <edit-state
      :show="showState"
      :state_id="stateId"
      :contactId="contactId"
      @cancel="hideState"
    />
    <edit-status
      :show="showStatus"
      :status_id="statusId"
      :contactId="contactId"
      @cancel="hideStatus"
    />
    <edit-board
      :show="showBoard"
      :board_id="boardId"
      :contactId="contactId"
      @cancel="hideBoard"
    />
    <new-edit-contact
      :show="showCreateModal"
      @cancel="onToggleEdit"
      :contact="contactToDelete"
    />
    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="confirmDeleteMessage"
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
    <call-modal
      :show="showDragmodal"
      :formatted="formattedTime"
      :hangup="CallHangup"
      :selectedContact="selectContact"
    />
    <div v-if="isLoading" class="items-center flex text-base justify-center">
      <spinner />
      <span>{{ $t('CONTACTS_PAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import { getCountryFlag } from 'dashboard/helper/flag';
import EditState from './EditState.vue';
import EditStatus from './EditStatus.vue';
import EditBoard from './EditBoard.vue';
import Spinner from 'shared/components/Spinner.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon';
import Modal from '../../conversation/contact/ContactAssignModal.vue';
import CallModal from 'dashboard/components/CallModal.vue';
import twilioCall from 'dashboard/mixins/twilioCall';
import NewEditContact from '../../conversation/contact/NewEditContact.vue';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
    Modal,
    EditState,
    EditStatus,
    CallModal,
    NewEditContact,
    EditBoard,
  },
  mixins: [clickaway, timeMixin, rtlMixin, twilioCall, alertMixin],
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    onClickContact: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    activeContactId: {
      type: [String, Number],
      default: '',
    },
    sortParam: {
      type: String,
      default: 'created_at',
    },
    sortOrder: {
      type: String,
      default: 'desc',
    },
  },
  data() {
    return {
      sortConfig: {},
      isModalVisible: false,
      showState: false,
      showStatus: false,
      showBoard: false,
      showDeleteModal: false,
      selectedRow: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
      visibleActionsRowId: null,
      showCreateModal: false,
      statusId: null,
      stateId: null,
      contactId: null,
      boardId: null,
      contactToDelete: {},
    };
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.contacts.map(item => {
        const custom = item.custom_attributes || {};
        const additional = item.additional_attributes || {};
        const { last_activity_at: lastActivityAt } = item;
        const { created_at: createdAt } = item;
        return {
          ...item,
          phone_number: item.phone_number || '---',
          company: additional.company_name || '---',
          profiles: additional.social_profiles || {},
          city: additional.city || '---',
          state: additional.state || '---',
          status: additional.status || '---',
          assigned_to: item.assignee ? item.assignee.name : '---',
          remark: item.remark || '---',
          source: custom.source || '---',
          country: additional.country,
          countryCode: additional.country_code,
          conversationsCount: item.conversations_count || '---',
          last_activity_at: lastActivityAt
            ? this.dynamicTime(lastActivityAt)
            : '---',
          created_at: createdAt ? this.dynamicTime(createdAt) : '---',
        };
      });
    },
    columns() {
      return [
        {
          field: 'name',
          key: 'name',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.NAME'),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.name || '',
          width: 220,
          renderBodyCell: ({ row }) => (
            <woot-button
              variant="clear"
              onClick={() => this.onClickContact(row.id)}
            >
              <div class="row--user-block cursor-pointer">
                <Thumbnail
                  src={row.thumbnail}
                  size="45px"
                  username={row.name}
                  status={row.availability_status}
                />
                <div class="user-block">
                  <h6 class="sub-block-title overflow-hidden whitespace-nowrap text-ellipsis text-slate-400 text-sm blue-color"
                  title={row.name.length > 15 ? row.name : ''}>
                    <router-link
                      to={`/app/accounts/${this.$route.params.accountId}/contacts/${row.id}`}
                      class="user-name"
                    ></router-link>
                    {row.name.length > 15
                      ? `${
                          row.name.charAt(0).toUpperCase() +
                          row.name.slice(1, 15)
                        }...`
                      : row.name.charAt(0).toUpperCase() + row.name.slice(1)}
                  </h6>
                  {/* <button class="button clear small link view-details--button">
                    {this.$t('CONTACTS_PAGE.LIST.VIEW_DETAILS')}
                  </button> */}
                </div>
              </div>
            </woot-button>
          ),
        },
        {
          field: 'email',
          key: 'email',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.EMAIL_ADDRESS'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.email || '',
          width: 240,
          renderBodyCell: ({ row }) => {
            if (row.email)
              return (
                <div class="overflow-hidden whitespace-nowrap text-ellipsis text-woot-500 dark:text-woot-500"
                title={row.email.length > 20 ? row.email : ''}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href={`mailto:${row.email}`}
                  >
                  {row.email.length > 20
                      ? `${
                          row.email.slice(0, 20)
                        }...`
                      : row.email}
                  </a>
                </div>
              );
            return '---';
          },
        },
        {
          field: 'phone_number',
          key: 'phone_number',
          sortBy: this.sortConfig.phone_number || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.PHONE_NUMBER'),
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            if (row.phone_number)
              return <div class="break-all">{row.phone_number}</div>;
            return '---';
          },
        },
        {
          field: 'created_at',
          key: 'created_at',
          sortBy: this.sortConfig.created_at || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.CREATED_AT'),
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            if (row.created_at)
              return <div class="break-all">{row.created_at}</div>;
            return '---';
          },
        },
        {
          field: 'assigned_to',
          key: 'assigned_to',
          sortBy: this.sortConfig.assigned_to || '',
          title: 'Assigned To',
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            if (row.assignee)
              return <div class="break-all">{row.assignee.name}</div>;
            return '---';
          },
        },
        // {
        //   field: 'source',
        //   key: 'source',
        //   sortBy: this.sortConfig.source || '',
        //   title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.SOURCE'),
        //   width: 200,
        //   align: this.isRTLView ? 'right' : 'left',

        //   renderBodyCell: ({ row }) => {
        //     if (row.source && row.source !== '---') {
        //       return <div className="light-grey">{row.source}</div>;
        //     } else {
        //       return (
        //         <div className="">
        //           <svg
        //             id="messenger"
        //             xmlns="http://www.w3.org/2000/svg"
        //             width="19.199"
        //             height="19.199"
        //             viewBox="0 0 19.199 19.199"
        //           >
        //             <path
        //               id="Path_2424"
        //               data-name="Path 2424"
        //               d="M.008,9.311A9.263,9.263,0,0,1,9.608,0a9.263,9.263,0,0,1,9.6,9.311,9.263,9.263,0,0,1-9.6,9.31,10.477,10.477,0,0,1-2.776-.368.768.768,0,0,0-.512.04l-1.912.84a.768.768,0,0,1-1.08-.68l-.056-1.712a.776.776,0,0,0-.256-.544A9.111,9.111,0,0,1,.009,9.312ZM6.664,7.559l-2.816,4.48a.451.451,0,0,0,.656.6l3.032-2.3a.565.565,0,0,1,.7,0l2.24,1.68a1.444,1.444,0,0,0,2.08-.384l2.816-4.48a.451.451,0,0,0-.656-.6l-3.032,2.3a.553.553,0,0,1-.688,0l-2.24-1.68a1.44,1.44,0,0,0-2.088.384Z"
        //               transform="translate(-0.008)"
        //               fill="#aaa"
        //             />
        //           </svg>
        //         </div>
        //       );
        //     }
        //   },
        // },
        {
          field: 'source',
          key: 'source',
          sortBy: this.sortConfig.source || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.SOURCE'),
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'company',
          key: 'company',
          sortBy: this.sortConfig.company_name || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.COMPANY'),
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            if (row.company) return <div class="break-all">{row.company}</div>;
            return '---';
          },
        },
        {
          field: 'city',
          key: 'city',
          sortBy: this.sortConfig.city || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.CITY'),
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            if (row.city) return <div class="break-all">{row.city}</div>;
            return '---';
          },
        },
        {
          field: 'state',
          key: 'state',
          sortBy: this.sortConfig.state || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.STATE'),
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            //if (row.state) return <div class="light-grey">{row.state}</div>;
            //return '---';
            if (row.state_name && row.state_name !== null) {
              // return <div className="light-grey">{row.state}</div>;
              return (
                <div class="ruby-div">
                  <div
                    class="state-color rounded-md cursor-pointer"
                    onClick={() => this.visibleState(row.state_id, row.id)}
                  >
                    {row.state_name}
                  </div>
                </div>
              );
            } else {
              return (
                <div class="ruby-div">
                  <div
                    class="state-color rounded-md cursor-pointer"
                    onClick={() => this.visibleState(row.state_id, row.id)}
                  >
                    Assign State
                  </div>
                </div>
              );
            }
          },
        },
        {
          field: 'status',
          key: 'status',
          sortBy: this.sortConfig.status || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.STATUS'),
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            if (row.status_name && row.status_name !== null) {
              return (
                <div class="ruby-div">
                  <div
                    class="status-color rounded-md cursor-pointer"
                    onClick={() => this.visibleStatus(row.status_id, row.id)} // Wrap the function call
                  >
                    {row.status_name}
                  </div>
                </div>
              );
            } else {
              return (
                <div class="ruby-div">
                  <div
                    class="state-color rounded-md cursor-pointer"
                    onClick={() => this.visibleStatus(row.status_id, row.id)} // Wrap the function call
                  >
                    Assign Status
                  </div>
                </div>
              );
            }
          },
        },
        {
          field: 'board',
          key: 'board',
          sortBy: this.sortConfig.board || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.BOARD'),
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            if (row.board_name && row.board_name != null) {
              // return <div class="light-grey">{row.board}</div>;
              return (
                <div class="ruby-div">
                  <div
                    class="status-board rounded-md cursor-pointer"
                    onClick={() => this.visibleBoard(row.board_id, row.id)}
                  >
                    {row.board_name}
                  </div>
                </div>
              );
            } else {
              return (
                <div class="ruby-div">
                  <div
                    class="state-color rounded-md cursor-pointer"
                    onClick={() => this.visibleBoard(row.board_id, row.id)}
                  >
                    Assign Board
                  </div>
                </div>
              );
            }
          },
        },
        {
          field: 'country',
          key: 'country',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.COUNTRY'),
          align: this.isRTLView ? 'right' : 'left',
          width: 200,
          sortBy: this.sortConfig.country || '',
          renderBodyCell: ({ row }) => {
            if (row.country) {
              return (
                <div class="overflow-hidden whitespace-nowrap text-ellipsis">
                  {`${getCountryFlag(row.countryCode)} ${row.country}`}
                </div>
              );
            }
            return '---';
          },
        },
        // {
        //   field: 'profiles',
        //   key: 'profiles',
        //   title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.SOCIAL_PROFILES'),
        //   align: this.isRTLView ? 'right' : 'left',
        //   width: 200,
        //   renderBodyCell: ({ row }) => {
        //     const { profiles } = row;

        //     const items = Object.keys(profiles);

        //     if (!items.length) return '---';

        //     return (
        //       <div class="cell--social-profiles flex gap-0.5 items-center">
        //         {items.map(
        //           profile =>
        //             profiles[profile] && (
        //               <a
        //                 target="_blank"
        //                 rel="noopener noreferrer nofollow"
        //                 href={`https://${profile}.com/${profiles[profile]}`}
        //               >
        //                 <FluentIcon icon={`brand-${profile}`} />
        //               </a>
        //             )
        //         )}
        //       </div>
        //     );
        //   },
        // },
        // {
        //   field: 'last_activity_at',
        //   key: 'last_activity_at',
        //   sortBy: this.sortConfig.last_activity_at || '',
        //   title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.LAST_ACTIVITY'),
        //   width: 200,
        //   align: this.isRTLView ? 'right' : 'left',
        // },
        // {
        //   field: 'conversationsCount',
        //   key: 'conversationsCount',
        //   title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.CONVERSATIONS'),
        //   width: 150,
        //   align: this.isRTLView ? 'right' : 'left',
        // },
        // {
        //   field: 'remarks',
        //   key: 'remarks',
        //   title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.REMARKS'),
        //   width: 200,
        //   align: this.isRTLView ? 'right' : 'left',
        // },
        {
          field: 'remark',
          key: 'remark',
          sortBy: this.sortConfig.assigned_to || '',
          title: 'Remark ',
          width: 200,
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
          const remark = row.additional_attributes?.remark || '';
          return (
            <div
              class="overflow-hidden whitespace-nowrap text-ellipsis"
              title={remark.length > 25 ? remark : ''}>
              {remark.length > 25
                ? `${remark.slice(0, 25)}...`
                : remark}
            </div>
          );
        },
        },
        {
          field: 'actions',
          key: 'actions',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.ACTIONS'),
          width: 100,
          renderBodyCell: ({ row }) => (
            <div class="relative">
              <div>
                <button
                  ref={`toggleButton_${row.id}`}
                  class="cursor-pointer"
                  onClick={() => this.toggleModal(row)}
                  v-tooltip="More Details"
                >
                  &#x22EE;
                </button>
              </div>

              {/* Conditionally render the action buttons based on visibility */}
              {this.visibleActionsRowId === row.id && (
                <div
                  ref={`actionDropdown_${row.id}`}
                  class="flex dropdown-position"
                >
                  <button
                    class="cursor-pointer button-background"
                    onClick={() => this.toggleComment(row)}
                    v-tooltip="Conversation"
                  >
                    <svg
                      id="comment-o"
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 21.977 18.837"
                    >
                      <path
                        id="Path_2456"
                        data-name="Path 2456"
                        d="M10.988,1.57a12.718,12.718,0,0,0-4.679.852,8.8,8.8,0,0,0-3.458,2.3A4.635,4.635,0,0,0,1.57,7.849a4.476,4.476,0,0,0,.877,2.618,7.474,7.474,0,0,0,2.471,2.152l1.067.613L5.654,14.41A8.794,8.794,0,0,1,4.8,16.519a12.1,12.1,0,0,0,3.372-2.1l.527-.466.7.074a13.878,13.878,0,0,0,1.594.1,12.718,12.718,0,0,0,4.679-.852,8.8,8.8,0,0,0,3.458-2.3,4.635,4.635,0,0,0,1.282-3.127,4.635,4.635,0,0,0-1.282-3.127,8.8,8.8,0,0,0-3.458-2.3A12.718,12.718,0,0,0,10.988,1.57ZM21.976,7.849A6.117,6.117,0,0,1,20.5,11.791a9.939,9.939,0,0,1-4,2.857A14.256,14.256,0,0,1,10.988,15.7a16.773,16.773,0,0,1-1.778-.1,13.4,13.4,0,0,1-5.641,2.968,9.24,9.24,0,0,1-1.4.27H2.109a.49.49,0,0,1-.331-.129.625.625,0,0,1-.2-.337v-.012a.161.161,0,0,1-.006-.147.328.328,0,0,0,.025-.123q-.006-.025.055-.117l.074-.11.086-.1.1-.11q.086-.1.38-.423l.423-.466q.129-.141.38-.484a4.985,4.985,0,0,0,.4-.625q.147-.282.331-.724a7.8,7.8,0,0,0,.319-.932,9,9,0,0,1-3.035-2.7A5.95,5.95,0,0,1,0,7.849,6.117,6.117,0,0,1,1.472,3.906a9.939,9.939,0,0,1,4-2.857A14.256,14.256,0,0,1,10.988,0a14.256,14.256,0,0,1,5.519,1.049,9.939,9.939,0,0,1,4,2.857A6.117,6.117,0,0,1,21.976,7.849Z"
                        fill="#6b6b6b"
                      />
                      <line
                        id="Line_271"
                        data-name="Line 271"
                        x2="9"
                        transform="translate(6.5 6.5)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Line_272"
                        data-name="Line 272"
                        x2="9"
                        transform="translate(6.5 9.5)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </svg>
                  </button>
                  <button
                    class="cursor-pointer button-background"
                    onClick={() => this.startupClient(row)}
                    v-tooltip="Call"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 18 17.947"
                    >
                      <path
                        id="bee4d62d12dd008a6ab2be9c9ad4c247"
                        d="M17.751,11.939a2.842,2.842,0,0,1-.605-.108,8.526,8.526,0,0,1-1.183-.352,1.806,1.806,0,0,0-2.24.9l-.2.406a11,11,0,0,1-2.4-1.806,11,11,0,0,1-1.806-2.4l.379-.253a1.806,1.806,0,0,0,.9-2.24A9.33,9.33,0,0,1,10.245,4.9c-.045-.2-.081-.406-.108-.614A2.71,2.71,0,0,0,7.427,2.04H4.717a2.71,2.71,0,0,0-2.71,3.08A17.162,17.162,0,0,0,16.929,19.987h.343a2.7,2.7,0,0,0,2.71-2.719v-2.71A2.71,2.71,0,0,0,17.751,11.939Zm.452,5.419a.9.9,0,0,1-.307.677.957.957,0,0,1-.741.226A15.355,15.355,0,0,1,3.868,4.912a.985.985,0,0,1,.226-.741.9.9,0,0,1,.677-.307h2.71a.9.9,0,0,1,.9.714q.054.37.135.732a10.044,10.044,0,0,0,.415,1.4L7.671,7.3a.9.9,0,0,0-.443,1.2,13.088,13.088,0,0,0,6.323,6.323.957.957,0,0,0,1.2-.47l.56-1.265a12.365,12.365,0,0,0,1.427.415q.361.081.732.135a.9.9,0,0,1,.714.9Z"
                        transform="translate(-1.982 -2.04)"
                        fill="#6b6b6b"
                      />
                    </svg>
                  </button>
                  <button
                    class="cursor-pointer button-background"
                    onClick={() => this.OpenEditContact(row)}
                    v-tooltip="Edit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 18.713 18.715"
                    >
                      <path
                        id="svg_55"
                        d="M19.06,2.862a3.209,3.209,0,0,1,0,4.536L8.331,18.129a2.017,2.017,0,0,1-.9.52L2.849,19.9a.672.672,0,0,1-.825-.826l1.251-4.586a2.017,2.017,0,0,1,.52-.9L14.524,2.862a3.209,3.209,0,0,1,4.536,0m-5.406,2.77L4.745,14.543a.672.672,0,0,0-.173.3l-.941,3.451,3.451-.941a.672.672,0,0,0,.3-.175L16.29,8.267Zm1.82-1.82-.87.87L17.24,7.317l.87-.87a1.864,1.864,0,1,0-2.636-2.636"
                        transform="translate(-1.745 -1.464)"
                        fill="#6b6b6b"
                        stroke="#6b6b6b"
                        stroke-width="0.5"
                      />
                    </svg>
                  </button>
                  <button
                    class="cursor-pointer button-background"
                    onClick={() => this.toggleDeleteModal(row)}
                    v-tooltip="Delete"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 17.125 19.979"
                    >
                      <g id="delete" transform="translate(-24 -12)">
                        <path
                          id="Path_2446"
                          data-name="Path 2446"
                          d="M72,72h1.427v8.562H72Z"
                          transform="translate(-42.292 -52.865)"
                          fill="#6b6b6b"
                        />
                        <path
                          id="Path_2447"
                          data-name="Path 2447"
                          d="M108,72h1.427v8.562H108Z"
                          transform="translate(-74.011 -52.865)"
                          fill="#6b6b6b"
                        />
                        <path
                          id="Path_2448"
                          data-name="Path 2448"
                          d="M24,36v1.427h1.427V51.7a1.427,1.427,0,0,0,1.427,1.427H38.27A1.427,1.427,0,0,0,39.7,51.7V37.427h1.427V36Zm2.854,15.7V37.427H38.27V51.7Z"
                          transform="translate(0 -21.146)"
                          fill="#6b6b6b"
                        />
                        <path
                          id="Path_2449"
                          data-name="Path 2449"
                          d="M72,12h5.708v1.427H72Z"
                          transform="translate(-42.292 0)"
                          fill="#6b6b6b"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              )}
            </div>
          ),
        },
      ];
    },
    confirmDeleteMessage() {
      return ` ${this.contactToDelete.name}?`;
    },
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    this.setSortConfig();
    console.log('Contact Page:::::', this.contacts);
    this.$store.dispatch('pipelines/getState');
    // document.addEventListener('click', this.handleOutsideClick);
    bus.$on('toggle-contact-heading-dropdown', this.toggleDropdownpipeline);
  },
  methods: {
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
    toggleModal(row) {
      if (this.visibleActionsRowId === row.id) {
        this.closeModalAssign(); // Close the modal if it's already open
      } else {
        this.openModal(row.id); // Open the modal for a new row
      }
    },
    openModal(rowId) {
      this.visibleActionsRowId = rowId;
      document.addEventListener('click', this.handleOutsideClick);
    },
    closeModalAssign() {
      this.visibleActionsRowId = null;
      // document.removeEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!this.visibleActionsRowId) return;
      const dropdownRef =
        this.$refs[`actionDropdown_${this.visibleActionsRowId}`];
      const buttonRef = this.$refs[`toggleButton_${this.visibleActionsRowId}`];
      if (
        dropdownRef.contains(event.target) == undefined ||
        buttonRef.contains(event.target) == undefined
      )
        return;
      if (
        this.visibleActionsRowId &&
        dropdownRef &&
        !dropdownRef.contains(event.target) &&
        buttonRef &&
        !buttonRef.contains(event.target)
      ) {
        this.closeModalAssign();
      }
    },
    beforeDestroy() {
      // Clean up event listener when component is destroyed
      document.removeEventListener('click', this.handleOutsideClick);
    },
    assignBoard() {
      console.log(`Assigning board to contact ${this.selectedRow.id}`);
    },
    assignState() {
      console.log(`Assigning state to contact ${this.selectedRow.id}`);
    },
    assignStatus() {
      console.log(`Assigning status to contact ${this.selectedRow.id}`);
    },
    toggleComment(row) {
      this.contactToDelete = row;
      this.isModalVisible = !this.isModalVisible;
      this.visibleActionsRowId = null;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    hideState() {
      this.showState = false;
    },
    hideStatus() {
      this.showStatus = false;
    },
    hideBoard() {
      this.showBoard = false;
    },
    visibleStatus(status_id, contactId) {
      this.statusId = status_id;
      this.showStatus = true;
      this.contactId = contactId;
    },
    visibleBoard(board_id, contactId) {
      this.boardId = board_id;
      this.showBoard = true;
      this.contactId = contactId;
    },
    visibleState(state_id, contactId) {
      this.stateId = state_id;
      this.showState = true;
      this.contactId = contactId;
    },
    onToggleEdit() {
      this.showCreateModal = false;
    },
    OpenEditContact(row) {
      this.contactToDelete = row;
      this.showCreateModal = true;
      this.visibleActionsRowId = null;
    },
    async deleteContact(contactId) {
      await this.$store.dispatch('contacts/delete', contactId);
      bus.$emit('on-assign');
      bus.$emit('dropdown-data-contact', true);
      this.showAlert('Contact Delete Successfully', { success: true });
    },
    toggleDeleteModal(row) {
      console.log('Contact Page:::::', this.contacts);
      this.contactToDelete = row;
      this.showDeleteModal = !this.showDeleteModal;
      this.visibleActionsRowId = null;
    },
    confirmDeletion() {
      this.deleteContact(this.contactToDelete.id);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
    },
    toggleDropdownpipeline() {
      this.closeModalAssign();
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-table-wrap::v-deep {
  .ve-table {
    @apply pb-8;
  }
  .row--user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col my-0 mx-2;
    }

    .user-name {
      @apply text-sm font-medium m-0 capitalize;
    }

    .view-details--button {
      @apply text-slate-600 dark:text-slate-200;
    }

    .user-email {
      @apply m-0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
    background-color: #aaaaaa;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    @apply -top-1;
  }
}

.cell--social-profiles {
  a {
    @apply text-slate-300 dark:text-slate-400 text-lg min-w-[2rem] text-center;
  }
}
.button-background {
  padding: 5px;
  border-radius: 50%;

  &:hover {
    background: #e5e5e5; // Change this to your desired hover background color
  }
}
.light-grey {
  color: #aaaaaa;
}
.state-color {
  color: red;
  background: #ffebeb;
  padding: 5px;
}
.status-color {
  color: blue;
  background: #f6f6ff;
  padding: 5px;
}
.status-board {
  color: blue;
  background: #f6f6ff;
  padding: 5px;
}
.ruby-div {
  display: ruby;
}
.dropdown-position {
  border-radius: 5px;
  text-align: center;
  min-width: 100px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px #00000033;
  position: absolute;
  right: 48px;
  top: -2px;
}
.blue-color {
  color: #007ff6;
}
</style>